@use 'sass:math';

@mixin labelFilter($initWidth) {
	$initPx: 880;
	$reductionAmount: 5;
	$pxStep: 30;

	width: $initWidth * 1%;

	@for $i from 0 through 5 {
		$newWidth: $initWidth + ($reductionAmount * $i);
		$newPx: $initPx - ($pxStep * $i);

		@media screen and (max-width: #{$newPx}px) {
			width: $newWidth * 1%;

			&.search {
				width: (math.div($newWidth, 2) - 0.25) * 1%;
			}
		}
	}
}

@mixin elementFilter($initWidth) {
	$initPx: 880;
	$reductionAmount: 5;
	$pxStep: 30;

	width: $initWidth * 1%;
	max-width: $initWidth * 1%;
	min-width: $initWidth * 1%;

	@for $i from 0 through 5 {
		$newWidth: $initWidth - ($reductionAmount * $i);
		$newPx: $initPx - ($pxStep * $i);

		@media screen and (max-width: #{$newPx}px) {
			width: $newWidth * 1%;
		}
	}
}

.card-content-simulation {
	padding: 0;
	.product-tabs {
		list-style-type: none;
		padding: 0;
	  }

	  .product-tab {
		padding: 10px;
		cursor: pointer;
		border-bottom: 1px solid #ccc;
	  }

	  .chart-container {
		width: 100%;
		height: 300px;
		position: relative;
	  }

	  .dataTables_wrapper .dataTables_scrollHeadInner,
		.dataTables_wrapper .dataTables_scrollBody table {
			width: 100% !important;
		}

		.dataTables_wrapper {
			width: 100% !important;
		}

		.dataTables_scrollHeadInner,
		.table.dataTable {
			width: 100% !important;
		}

	  #salesforceChart {
		width: 100% !important;
		height: 100% !important;
	  }

	  .custom-tooltip {
		font-size: 1.2rem;
		padding: 10px;
		max-width: 300px;
		white-space: normal;
	  }

	  .product-tab.active {
		background: linear-gradient(to right, #D9E8FF 0%, #FFFFFF 100%);
		color: #173091;
		padding: 10px;
		font-weight: bold;
		transition: background 0.3s ease;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.mat-mdc-card {
		box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1);
		min-height: 101px;
		// max-width: 225px;
	}

	.mat-mdc-card-header {
		padding: 10px 9px 0;
	}

	.p-main {
		padding: 1rem 1.75rem 1rem 1.75rem;
	}

	.p-tabview-nav-container {
		display: none !important;
	}

	.p-tabview-panels {
		padding: 0;
	}

	.grid-main {
		height: calc(100vh - 6rem);

		.sticky-header {
			position: sticky;
			top: 0;
			left: 0;
			right: 0;
			z-index: 10;
			background: white;
			border-radius: 0.5rem;
			-webkit-border-radius: 0.5rem;
			-moz-border-radius: 0.5rem;
			-ms-border-radius: 0.5rem;
			-o-border-radius: 0.5rem;
		}

		.col-header {
			padding-bottom: 0 !important;
		}

		.p-selectbutton {
			.p-button {
				border: none !important;
				background: #fff;
				color: black;

				&.p-highlight {
					background: #eef5ff;
				}

				&:hover,
				&:focus {
					background: #d9e8ff !important;
					color: var(--primary-color) !important;
				}
			}

			&.filter-view .p-button {
				height: 2.3rem;

				&:nth-child(1) {
					border: 1px solid black !important;
					border-right: none !important;
				}

				&:nth-child(2),
				&:nth-child(3) {
					border: 1px solid black !important;
					border-right: none !important;
					border-left: none !important;
				}

				&:nth-child(4) {
					border: 1px solid black !important;
					border-left: none !important;
				}
			}

			&.filter-view.disabled .p-button {
				cursor: not-allowed;
				color: #8a8ad6;

				&.p-highlight {
					background: #e7e7fb;
				}

				&:nth-child(1) {
					border: 1px solid #8a8ad6 !important;
					border-right: none !important;
				}

				&:nth-child(2) {
					border: 1px solid #8a8ad6 !important;
					border-left: none !important;
				}
			}
		}

		.p-button.disabled {
			background: #e7e7fb;
			color: #8a8ad6;
			border: none !important;
			cursor: not-allowed;
		}

		.p-button.button-header {
			border: none !important;
			background: #eef5ff;
			color: var(--primary-color);

			.icon {
				fill: var(--primary-color) !important;
			}

			&.active,
			&:focus {
				background: var(--primary-color);
				color: #ffffff;

				.icon {
					fill: #ffffff !important;
				}
			}

			&:hover {
				background: #d9e8ff !important;
				color: var(--primary-color) !important;

				.icon {
					fill: var(--primary-color) !important;
				}
			}
		}

		.p-button.button-run {
			background: rgb(22, 23, 61);
		}

		.col-filter {
			height: 10rem;
			border-top: 1px solid #e9edf1;
			border-bottom: 1px solid #e9edf1;
			background: #f9fafc;
			padding-top: 0;
			padding-bottom: 0;
			margin-bottom: 1rem;
		}

		.label-filter {
			font-size: 0.75rem;

			@include labelFilter(18);

			&.search {
				@include labelFilter(20);
			}
		}

		.element-filter {
			@include elementFilter(82);

			&.search {
				@include elementFilter(80);

				.filter-content {
					width: 102%;
				}
			}
		}

		.filter-content {
			width: 100%;
			height: 2.25rem;

			&.min-token {
				.p-multiselect-token {
					width: 6rem;
				}
			}

			.p-autocomplete-multiple-container {
				flex-wrap: nowrap;
				overflow-x: auto;
			}

			.p-treeselect-label {
				background: #eef5ff !important;
				color: var(--primary-color) !important;
				border-radius: 4px;
				font-size: 14px;
				width: fit-content;
				margin: 0.3rem 0.7rem;
				height: 1.5rem !important;
				padding: 0.3rem 0.8rem;
			}
		}

		.box-simulation-list-item {
			border: 1px solid #eef5ff;
			border-radius: 6px;
			padding: 0.5rem 1rem;
			min-height: 3rem;

			&.active {
				background: #eef5ff;
				color: var(--primary-color) !important;
			}

			.box-simulation-generated {
				border: 1.5px solid #eef5ff;
				border-radius: 6px;
				padding: 0.1rem 0.5rem;
				text-transform: uppercase;
				font-size: 0.8rem;

				&.empty {
					color: #eef5ff;
				}

				&.active {
					background: #eef5ff;
					color: var(--primary-color);
				}
			}

			.p-button.button-go-to-simulation {
				border: none !important;
				background: #eef5ff;
				color: var(--primary-color);

				&:focus,
				&:hover {
					background: #ccccf8 !important;
					color: var(--primary-color) !important;
				}
			}
		}

		.col-content {
			padding: 0;

			.p-tabview-panels {
				padding: 0 1rem;
			}
		}

		.label-filter-details {
			font-size: 0.875rem;
		}

		.box-simulation-item {
			border: 1px solid #eef5ff;
			border-radius: 6px;
			padding: 0.5rem 1rem;
		}

		.box-simulation-item-content {
			border: 1px solid #eef5ff;
			border-radius: 6px;
			padding: 1rem;
			width: 100%;
			height: 100%;

			p {
				line-height: 1rem;
				font-size: 0.875rem;
				margin: 0;
			}
		}

		.text-color-normal {
			color: #5b6770;
		}

		.text-size-normal {
			font-size: 0.75rem;
		}

		.text-title {
			font-size: 0.95rem !important;
			margin-bottom: 1rem !important;
		}

		.box-simulation-item-user-case-list {
			border: 1px solid #eef5ff;
			border-radius: 0;
			padding: 0;
			width: 100%;
			height: 100%;

			.box-simulation-item-user-case-list-item {
				border-bottom: 1px solid #eef5ff;
				padding: 1rem;
				width: 100%;
				height: 3rem;

				&.header {
					background: #f9fafc;
					font-weight: bold;
				}

				&.active {
					background: var(--primary-color);
					color: white;
				}
			}
		}

		.box-simulation-item-use-case-text {
			height: 100%;
			padding: 0 1rem;

			p {
				line-height: 1rem;
				font-size: 0.8rem;
				margin: 0;
			}
		}

		.chip {
			background: #eef5ff;
			color: var(--primary-color);
			border-radius: 6px;
			padding: 0.3rem 0.5rem;
			font-size: 0.8rem;
			width: fit-content;
		}

		.box-simulation-item-use-case-ai-enablers {
			border: 1px solid #eef5ff;
			border-radius: 6px;
			padding: 0.5rem 1rem;
		}

		.text-empty {
			width: 30%;
			text-align: center;
			font-size: 1.2rem;
		}

		.ngx-slider {
			margin: 5px 0 !important;

			.ngx-slider-bubble {
				display: none;
			}

			.ngx-slider-pointer {
				background-color: var(--primary-color);
			}
		}
	}

	.buttons-actions-main {
		position: relative;
		top: 24px;
		right: 36%;
		padding: 0;
		z-index: 1;
	}

	.buttons-actions {
		position: relative;
		top: -2rem;
		right: 0.5rem;
		padding: 0;
	}

	.table-simulation-use-cases {
		th {
			text-align: left;
		}
	}

	.table-simulation-use-cases {
		th {
			text-align: center;

			&:first-of-type {
				text-align: left;
			}
		}
	}

	.table-recommended-use-cases,
	.table-simulation-use-cases {
		th {
			background: #f9fafc;
		}

		td {
			text-align: left;
		}

		.dataTable > tbody > tr.selected > * {
			box-shadow: inset 0 0 0 9999px #eff1fd;
			color: var(--primary-color);
		}
	}

	.table-simulation-use-cases {
		.dt-buttons,
		.dt-search {
			display: none !important;
		}
	}

	.table-recommended-use-cases {
		.dt-search label {
			//display: none !important;
		}

		.dt-buttons,
		.dt-search {
			//display: none !important;
		}
	}

	.accordion {
		width: 100%;

		.p-accordion-header {
			display: none !important;
		}

		.p-accordion-content {
			padding: 0 !important;
			border-radius: 0 !important;
			border: none !important;
		}

		.background-filter {
			border-top: 1px solid #e9edf1;
			border-bottom: 1px solid #e9edf1;
			background: #f9fafc;

			.label-filter {
				font-size: 0.75rem;

				@include labelFilter(18);

				&.search {
					@include labelFilter(8.75);
				}
			}

			.element-filter {
				@include elementFilter(82);

				.filter-content {
					width: 100%;
					max-height: 2.45rem;
				}
			}
		}
	}

	.grid-simulation {
		display: grid;
		gap: 0.75rem;

		$initWidth: 25;
		$initPx: 880;
		$reductionAmount: 10;
		$vwStep: 30;

		grid-template-columns: repeat(auto-fill, minmax(25vw, 3fr));

		@for $i from 0 through 5 {
			$newWidth: $initWidth + ($reductionAmount * $i);
			$newPx: $initPx - ($vwStep * $i);

			@media screen and (max-width: #{$newPx}px) {
				grid-template-columns: repeat(auto-fill, minmax(#{$newWidth}vw, 3fr));
			}
		}
	}

	.grid-solutions {
		display: grid;
		gap: 0.75rem;

		$initWidth: 25;
		$initPx: 880;
		$reductionAmount: 10;
		$vwStep: 30;

		grid-template-columns: repeat(auto-fill, minmax(20vw, 3fr));

		@media screen and (max-width: 1010px) {
			grid-template-columns: repeat(auto-fill, minmax(25vw, 3fr));
		}

		@for $i from 0 through 5 {
			$newWidth: $initWidth + ($reductionAmount * $i);
			$newPx: $initPx - ($vwStep * $i);

			@media screen and (max-width: #{$newPx}px) {
				grid-template-columns: repeat(auto-fill, minmax(#{$newWidth}vw, 3fr));
			}
		}
	}

	.card-data {
		width: 100%;
		height: 100%;
		max-height: 40vh;
		min-height: 14vh;
		object-fit: cover;

		.card-title {
			height: 50%;
		}

		.background-card {
			background: rgba(255, 153, 0, 0.05);
		}

		.description-use-case {
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.125rem;
			letter-spacing: 0.00138rem;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			max-width: 100%;
			padding-block: 1rem;
			height: 47%;
			max-height: 3.9em;
		}

		.button-trash {
			position: absolute;
			bottom: 0;
			right: 0.5rem;
			width: 17px;
		}
	}

	.icon-solution {
		width: 2.5rem;
		height: 2.5rem;
	}

	.background-heatmap {
		text-align: center !important;
		height: 2rem;
	}

	.background-none {
		@extend .background-heatmap;
		background-color: #f9fafc;
	}

	.background-low {
		@extend .background-heatmap;
		color: #9f5252;
		background-color: #fbeded;
		border-radius: 6px;
	}

	.background-medium {
		@extend .background-heatmap;
		color: #8d744e;
		background-color: #fcf2d7;
		border-radius: 6px;
	}

	.background-high {
		@extend .background-heatmap;
		color: #579043;
		background-color: #dbf3de;
		border-radius: 6px;
	}

	.use-case-simulation-item {
		.header-business {
			background-color: #eff1fd;
			text-align: center;
		}

		.box-simulation-item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
		}

		.box-simulation-item .header-business {
			flex-shrink: 0;
		}

		.box-simulation-item .flex-grow-1 {
			flex-grow: 1;
		}
		.p-accordion-tab {
			margin: 0.5rem !important;

			.p-accordion-header-link {
				background: #ffffff !important;
				border: none !important;
			}

			.p-accordion-content {
				border: none !important;
			}
		}
	}

	.input-simulation {
		all: unset;
		cursor: pointer !important;
		width: 100% !important;
		padding-top: 1rem !important;
	}
}

.simulation-add-process-modal {
	.p-treeselect-label {
		background: #eef5ff !important;
		color: var(--primary-color) !important;
		border-radius: 4px;
		font-size: 14px;
		width: fit-content;
		margin: 0.3rem 0.7rem;
		height: 1.5rem !important;
		padding: 0.3rem 0.8rem;
	}
}

.dialog-add-simulation {
	.grid-simulation {
		display: grid;
		gap: 0.75rem;

		$initWidth: 15;
		$initPx: 880;
		$reductionAmount: 10;
		$vwStep: 30;

		grid-template-columns: repeat(auto-fill, minmax(15vw, 3fr));

		@for $i from 0 through 5 {
			$newWidth: $initWidth + ($reductionAmount * $i);
			$newPx: $initPx - ($vwStep * $i);

			@media screen and (max-width: #{$newPx}px) {
				grid-template-columns: repeat(auto-fill, minmax(#{$newWidth}vw, 3fr));
			}
		}
	}

	.box-simulation-list-item {
		border: 1px solid #eef5ff;
		border-radius: 6px;
		padding: 0.5rem 1rem;
		min-height: 3rem;

		&.active {
			background: #eef5ff;
			color: var(--primary-color) !important;
		}

		.box-simulation-generated {
			border: 1.5px solid #eef5ff;
			border-radius: 6px;
			padding: 0.1rem 0.5rem;
			text-transform: uppercase;
			font-size: 0.8rem;

			&.empty {
				color: #eef5ff;
			}

			&.active {
				background: #eef5ff;
				color: var(--primary-color);
			}
		}

		.p-button.button-go-to-simulation {
			border: none !important;
			background: #eef5ff;
			color: var(--primary-color);

			&:focus,
			&:hover {
				background: #ccccf8 !important;
				color: var(--primary-color) !important;
			}
		}
	}
}

.table-recommended-use-cases {
	width: 100%;
}

app-simulation-enterprise-context {
	width: 100%;
}

.use-case-library-item {
	overflow-y: auto;
	max-height: 70vh;

	.header-business {
		background-color: #eff1fd;
		text-align: center;
	}

	.box-simulation-item {
		border: 1px solid #eef5ff;
		border-radius: 6px;
		padding: 0.5rem 1rem;
	}

	.background-heatmap {
		text-align: center !important;
		height: 2rem;
	}

	.background-none {
		@extend .background-heatmap;
		background-color: #f9fafc;
	}

	.background-low {
		@extend .background-heatmap;
		color: #9f5252;
		background-color: #fbeded;
		border-radius: 6px;
	}

	.background-medium {
		@extend .background-heatmap;
		color: #8d744e;
		background-color: #fcf2d7;
		border-radius: 6px;
	}

	.background-high {
		@extend .background-heatmap;
		color: #579043;
		background-color: #dbf3de;
		border-radius: 6px;
	}

	.header-business {
		background-color: #eff1fd;
		text-align: center;
	}

	.p-accordion-tab {
		margin: 0.5rem !important;

		.p-accordion-header-link {
			background: #ffffff !important;
			border: none !important;
		}

		.p-accordion-content {
			border: none !important;
		}
	}
}
