:root {
	--font-family: 'Inter', sans-serif !important;
	font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
	--primary-color: #1b54f8;
	--border-primary: #7171bb;
	--surface-100: #d5def8;
	--surface-800: #1b54f8;
	--surface-900: #002f5f;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
