.card-content-maturity-framework {
    padding: 1rem 1.75rem 1rem 1.75rem;

    .buttons-header {
        .p-button:not(.new-data) {
            border-color: #c8d3eb !important;
            background: #fff;
            color: #c8d3eb;

            &.active,
            &.activable:hover {
                background: #eef5ff;
                color: var(--primary-color);
            }

            &:hover,
            &:focus {
                color: var(--primary-color);
                border-color: var(--primary-color) !important;
            }
        }
    }

    .grid-main {
        height: calc(100vh - 6rem); //ponerlo en mediaquery?

        .card-pillars {
            background: none;
        }

        .scroll-height {
            height: calc(100vh - 15rem);
        }

        .button-add-step {
            &:hover,
            &:active {
                .pi {
                    color: white !important;
                }
            }
        }
    }

    .maturity-chart svg {
        width: 100%;
        height: calc(100vh - 16rem);
        margin: 0;
        padding: 0;
    }

    .no-margin {
        margin: 0;

        li {
            margin-bottom: 1rem;
        }
    }

    .maturity-progress {
        background: #f9fafc !important;
        border: 1px solid #eeeeee !important;
        border-radius: 4px;

        .p-progressbar-value {
            background: linear-gradient(90deg, #1b54f800 1%, #1b54f854 100%) !important;
            border-radius: 4px;

            .p-progressbar-label {
                display: none !important;
            }
        }
    }

    .tippy-box {
        background-color: #ffffff;
        color: #333;
        -webkit-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
        box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
    }

    text.cls-7,
    circle.cls-5 {
        cursor: pointer;
    }
}

.maturity-framework-assessments {
    .p-dialog-content {
        border-radius: 8px !important;

        .card-content-stepper {
            border: none !important;
            padding: 1.5rem 1rem 0.5rem 1rem;
            border-radius: 0;

            .p-steps {
                position: absolute;
                width: 65%;
                right: 5%;
                z-index: 10;
                background: rgba(255, 255, 255, 0.49);

                .p-menuitem-link {
                    display: flex;
                    flex-direction: column-reverse;
                    padding-bottom: 0.5rem;
                    cursor: pointer;

                    &:focus {
                        box-shadow: none !important;
                    }
                }

                .p-steps-title {
                    font-size: 0.7rem !important;
                    margin-bottom: 1rem;
                    text-wrap: nowrap !important;
                    text-align: center;
                    margin-left: 0.2rem;
                    margin-right: 0.2rem;
                }

                .p-highlight .p-steps-number {
                    background: var(--primary-color) !important;
                }

                .p-highlight .p-steps-title {
                    color: var(--primary-color) !important;
                }

                .p-steps-number {
                    border: 2px solid var(--primary-color) !important;
                    min-width: 1rem !important;
                    height: 1rem !important;
                    font-size: 0 !important;
                    border-radius: 50% !important;
                    background: white !important;
                }

                .p-steps-item {
                    width: 12.5%;
                }

                .p-steps-item:before {
                    height: 2px;
                    border: none !important;
                    background: var(--primary-color) !important;
                    margin-top: 1rem !important;

                    @media screen and (max-width: 1650px) {
                        margin-top: 0.9rem !important;
                    }
                }

                .p-steps-item.p-highlight ~ * {
                    &:before {
                        background: rgb(69 69 149 / 13%) !important;
                    }
                }

                .p-steps-item.p-highlight:before {
                    background: linear-gradient(90deg, var(--primary-color) 50%, #1b54f800 110%) !important;
                }
            }

            .close-button {
                top: 1rem;
                right: 1rem;
                position: absolute;
                color: var(--bluegray-300);
                font-size: 0.25rem;
                height: 2rem !important;
                width: 2rem !important;

                &:hover {
                    background: var(--bluegray-400) !important;
                    border-color: var(--bluegray-400) !important;
                    color: white;
                }
            }

            .scroll-height {
                height: calc(100vh - 16.1rem);
                margin-top: 2rem;

                .ngx-slider .ngx-slider-tick-legend {
                    position: absolute;
                    top: -24px;
                }

                .text-4 {
                    color: #383890;
                }

                .text-3 {
                    color: #6b9e00;
                }

                .text-2 {
                    color: #ff7d00;
                }

                .text-1 {
                    color: #5b6770;
                }

                .text-na {
                    color: #c1c6c8;
                    text-wrap: nowrap !important;
                }
            }

            .chart-data {
                position: relative;
                max-width: 10rem;
                height: 3rem;

                canvas {
                    height: 4rem !important;
                }

                .percent {
                    position: absolute;
                    bottom: -35%;
                    text-align: center;
                    width: 100%;
                    font-size: 1rem;
                }
            }

            .p-tabview-panels {
                padding-top: 0;
            }

            .p-tabview-nav-container {
                display: none;
            }

            .btnAI {
                &.active,
                &:hover {
                    path {
                        fill: white;
                    }
                }

                path {
                    fill: #1b54f8;
                }
            }

            .stepper {
                background: transparent;
                display: flex;
                width: 100%;
                gap: 5px;
                overflow: auto;
            }

            $shape-inset: 1.25rem;

            .arrow {
                background: #f9fafc;
                color: #7c878e;
                cursor: pointer;
                clip-path: polygon(
                    0% 0%,
                    calc(100% - #{$shape-inset}) 0%,
                    100% 50%,
                    calc(100% - #{$shape-inset}) 100%,
                    0% 100%,
                    #{$shape-inset} 50%
                );
                padding: 1rem calc(1rem + #{$shape-inset});
                position: relative;
                width: calc(100% + #{$shape-inset});
                border-radius: 5px;
                line-height: 16px;
                letter-spacing: 0.038px;
                font-size: 0.8rem;
                font-style: normal;
                font-weight: 600;
                display: flex;
                align-items: center;

                &:first-of-type {
                    padding-left: 2rem;
                    clip-path: polygon(
                        0% 0%,
                        calc(100% - #{$shape-inset}) 0%,
                        100% 50%,
                        calc(100% - #{$shape-inset}) 100%,
                        0% 100%
                    );
                }

                &:not(:first-of-type) {
                    margin-left: -$shape-inset;
                }

                &.active {
                    background: #eff1fd;
                    color: #1b54f8;
                }

                &.hoverable:hover {
                    background: #eff1fd;
                    color: #1b54f8;
                }
            }

            .content {
                .content-editable {
                    overflow: auto;
                    height: 40vh;
                    padding-top: 1rem;
                }
            }
        }
    }
}

li.check-box-list-item {
    pointer-events: none;
    margin-bottom: 20px;
}
