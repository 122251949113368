
// Inline form editing
// Hide the label and allow the field to take the full width
div.DTE_Inline {
	position: relative;
	display: table;
	width: 100%;

	div.DTE_Inline_Field,
	div.DTE_Inline_Buttons {
		display: table-cell;
		vertical-align: middle;

		div.DTE_Field {
			padding: 0;

			>label {
				display: none;
			}

			input[type="color"],
			input[type="date"],
			input[type="datetime"],
			input[type="datetime-local"],
			input[type="email"],
			input[type="month"],
			input[type="number"],
			input[type="password"],
			input[type="search"],
			input[type="tel"],
			input[type="text"],
			input[type="time"],
			input[type="url"],
			input[type="week"] {
				width: 100%;
			}
		}

		div.DTE_Form_Buttons button {
			margin: -6px 0 -6px 4px;
			padding: 5px;
		}
	}

	// Have the input types take up full space, taking into account the cell padding
	div.DTE_Field input[type="color"],
	div.DTE_Field input[type="date"],
	div.DTE_Field input[type="datetime"],
	div.DTE_Field input[type="datetime-local"],
	div.DTE_Field input[type="email"],
	div.DTE_Field input[type="month"],
	div.DTE_Field input[type="number"],
	div.DTE_Field input[type="password"],
	div.DTE_Field input[type="search"],
	div.DTE_Field input[type="tel"],
	div.DTE_Field input[type="text"],
	div.DTE_Field input[type="time"],
	div.DTE_Field input[type="url"],
	div.DTE_Field input[type="week"] {
		margin: -6px 0;
	}

	div.DTE_Field_Error,
	div.DTE_Form_Error {
		font-size: 11px;
		line-height: 1.2em;
		padding: 0;
		margin-top: 10px;

		&:empty {
			margin-top: 0;
		}
	}
}

// Responsive integration
span.dtr-data div.DTE_Inline {
	display: inline-table;
}