.sign-in {

	margin-right: 0;
	background-color: #ebeff8;

	@media screen and (max-width: 767px) {
		margin-left: 0;
	}

	.welcome {
		@media screen and (max-width: 767px) {
			display: none;
		}

		.welcome-background {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			z-index: 0;
			background: url('/assets/rtp/img/sign-in.png') no-repeat;
			background-size: 250%;
			background-position-x: 80%;
			background-position-y: 40%;
		}

		.welcome-color {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			z-index: 5;
			background: #30115f;
			opacity: 0.5;
		}

		.welcome-bar {
			background-color: #7585da;
			height: 5px;
		}
	}

	.welcome-text {
		z-index: 10;
	}

	.form {
		z-index: 100;
		background: #ebeff8;

		.form-content {
			.form-inputs {
				width: 100%;
			}
		}

		.form-input-text {
			&:focus {
				box-shadow: none !important;
				border: 1px solid var(--primary-color);
			}
		}

		.p-button {
			border: none !important;

			&:focus {
				box-shadow: none;
			}
		}
	}

	.gap-3 {
		gap: 1.5rem !important
	}
}
