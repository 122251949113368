@use 'sass:math';

@mixin labelFilter($initWidth) {
    $initPx: 880;
    $reductionAmount: 5;
    $pxStep: 30;

    width: $initWidth * 1%;

    @for $i from 0 through 5 {
        $newWidth: $initWidth + ($reductionAmount * $i);
        $newPx: $initPx - ($pxStep * $i);

        @media screen and (max-width: #{$newPx}px) {
            width: $newWidth * 1%;

            &.search {
                width: (math.div($newWidth, 2) - 0.25) * 1%;
            }
        }
    }
}

@mixin elementFilter($initWidth) {
    $initPx: 880;
    $reductionAmount: 5;
    $pxStep: 30;

    width: $initWidth * 1%;
    max-width: $initWidth * 1%;
    min-width: $initWidth * 1%;

    @for $i from 0 through 5 {
        $newWidth: $initWidth - ($reductionAmount * $i);
        $newPx: $initPx - ($pxStep * $i);

        @media screen and (max-width: #{$newPx}px) {
            width: $newWidth * 1%;
        }
    }
}

@mixin dataview-solutions($initHeight) {
    $initPx: 610;
    $reductionAmount: 4;
    $pxStep: 25;

    height: #{$initHeight}vh;
    overflow: auto;

    @for $i from 0 through 5 {
        $newHeight: $initHeight - ($reductionAmount * $i);
        $newPx: $initPx - ($pxStep * $i);

        @media screen and (max-height: #{$newPx}px) {
            height: #{$newHeight}vh;
        }
    }
}

.card-content-solutions {
    .data-header {
        background: transparent !important;
        border: none !important;
        padding-bottom: 0.5rem;

        .header-buttons {
            .p-button:not(.new-solution) {
                border: none !important;
                background: #fff;
                color: #c8d3eb;

                &.active {
                    background: #eef5ff;
                    color: var(--primary-color);
                }

                &:hover,
                &:focus {
                    color: var(--primary-color);
                }
            }
        }

        .grid-selects {
            color: #7c878e;
            font-size: 11px;
            line-height: 1rem;
            text-align: left;

            .p-dropdown.p-focus {
                box-shadow: none;
                border-color: var(--primary-color);
            }
        }
    }

    .accordion {
        .p-accordion-header {
            display: none !important;
        }

        .p-accordion-content {
            padding: 0 !important;
            border-radius: 0 !important;
            border: none !important;
        }

        .background-filter {
            .label-filter {
                font-size: 0.75rem;

                @include labelFilter(18);

                &.search {
                    @include labelFilter(8.75);
                }
            }

            .element-filter {
                @include elementFilter(82);

                .filter-content {
                    width: 100%;
                    max-height: 2.45rem;
                }
            }
        }
    }

    .p-datatable.p-component {
        width: 100%;
        height: 70vh;
        overflow: auto;
    }

    p-dataview {
        width: 100%;
    }

    .grid-loading {
        @include dataview-solutions(70);
    }

    .p-dataview.p-component {
        width: 100%;
        height: 100%;
        overflow: hidden;

        &.p-dataview-grid .p-dataview-content {
            @include dataview-solutions(70);
        }

        &.minimal.p-dataview-grid .p-dataview-content {
            @include dataview-solutions(42);
        }

        .p-grid {
            display: grid;
            gap: 0.75rem;

            $initWidth: 25;
            $initPx: 880;
            $reductionAmount: 10;
            $vwStep: 30;

            grid-template-columns: repeat(auto-fill, minmax(20vw, 3fr));

            @media screen and (max-width: 1010px) {
                grid-template-columns: repeat(auto-fill, minmax(25vw, 3fr));
            }

            @for $i from 0 through 5 {
                $newWidth: $initWidth + ($reductionAmount * $i);
                $newPx: $initPx - ($vwStep * $i);

                @media screen and (max-width: #{$newPx}px) {
                    grid-template-columns: repeat(auto-fill, minmax(#{$newWidth}vw, 3fr));
                }
            }
        }
    }

    .card-data {
        width: 100%;
        height: 100%;
        max-height: 40vh;
        min-height: 25vh;
        object-fit: cover;

        .card-title {
            height: 50%;
        }

        .background-card {
            background: rgba(255, 153, 0, 0.05);
        }

        .description-use-case {
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.125rem;
            letter-spacing: 0.00138rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-width: 100%;
            padding-block: 1rem;
            height: 47%;
            max-height: 3.9em;
        }

        .button-trash {
            position: absolute;
            bottom: 0;
            right: 0.5rem;
            width: 17px;
        }
    }
}

.simulation-sme-prompt-modal {
    color: #252525;

    p {
        margin-bottom: 0.5rem;
        line-height: 1.1rem;
        color: #252525;
    }

    li {
        color: #252525;
    }
}
