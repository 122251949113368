.filter-sidebar {

	&.extend {
		div[filter-sidebar=""] {
			margin: 1.25rem 0.78rem;
			max-height: 80vh;
		}
	}

	div[filter-sidebar=""] {
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 78vh;
	}

	input.p-inputtext {
		width: 100%;
	}
}
