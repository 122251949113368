.card-content-use-cases {
	.data-header {
		background: transparent !important;
		border: none !important;
		padding-bottom: 0.5rem;

		.header-buttons {
			.p-button:not(.new-solution) {
				border: none !important;
				background: #eff1fd;
				color: #1b54f8;

				&.active,
				&.activable:hover {
					background: #eef5ff;
					color: var(--primary-color);
				}

				&:hover,
				&:focus {
					color: var(--primary-color);
				}
			}
		}

		.grid-selects {
			color: #7c878e;
			font-size: 11px;
			line-height: 1rem;
			text-align: left;

			.p-dropdown.p-focus {
				box-shadow: none;
				border-color: var(--primary-color);
			}
		}

		.col-name,
		.col-value {
			p {
				line-height: 1rem !important;
			}
		}
	}

	.p-tabview-nav-container {
		display: none !important;
	}

	.p-tabview-panels {
		padding: 0 !important;

		.p-tabview-panel {
			height: 100%;
		}
	}

	.grid-dataview {
		width: 99%;
	}

	.dataview-portfolio {
		.p-dataview-content {
			height: 56vh !important;
			overflow-x: hidden !important;

			.p-grid {
				width: 97%;
			}
		}

		&.paginator {
			.p-dataview-content {
				height: 46vh !important;
			}
		}
	}

	p-dataview {
		width: 100%;

		.p-dataview-header {
			background: transparent !important;

			&,
			& > div {
				padding: 0;
			}
		}
	}

	.label-1 {
		$initWidth: 18;
		$initPx: 880;
		$reductionAmount: 5;
		$pxStep: 30;

		width: $initWidth * 1%;

		@for $i from 0 through 5 {
			$newWidth: $initWidth + ($reductionAmount * $i);
			$newPx: $initPx - ($pxStep * $i);

			@media screen and (max-width: #{$newPx}px) {
				width: $newWidth * 1%;
			}
		}
	}

	.filter-1 {
		$initWidth: 82;
		$initPx: 880;
		$reductionAmount: 5;
		$pxStep: 30;

		width: $initWidth * 1%;
		max-width: $initWidth * 1%;
		min-width: $initWidth * 1%;

		@for $i from 0 through 5 {
			$newWidth: $initWidth - ($reductionAmount * $i);
			$newPx: $initPx - ($pxStep * $i);

			@media screen and (max-width: #{$newPx}px) {
				width: $newWidth * 1%;
			}
		}

		.filter-content {
			width: 100%;
		}
	}

	.label-2 {
		width: 30%;

		@media screen and (max-width: 991px) {
			width: 42%;
		}
	}

	.filter-2 {
		width: 70%;

		.filter-content {
			width: 100%;

			.p-chip-text {
				margin-top: 0 !important;
				margin-bottom: 0 !important;
			}
		}
	}

	.box-select-low {
		.p-chip {
			background: #f2dae2 !important;
			color: #994d4d !important;
		}
	}

	.box-select-medium {
		.p-chip {
			background: #f7f1d2 !important;
			color: #99794d !important;
		}
	}

	.box-select-high {
		.p-chip {
			background: #def2da !important;
			color: #66994d !important;
		}
	}

	.data-ai {
		height: 69vh;

		.button-header {
			border: none !important;
			background: #fff;
			color: #c8d3eb;

			&.active {
				background: #eef5ff;
				color: var(--primary-color);
			}

			&.left {
				border-radius: 4px 0 0 4px;
			}

			&.right {
				border-radius: 0 4px 4px 0;
			}

			&:hover,
			&:focus {
				color: var(--primary-color);

				&:not(.active) {
					background: #fff;
				}
			}
		}

		.content {
			overflow: auto;
			height: 69vh;

			* {
				font-size: 1rem;
				line-height: 1.5rem;
				color: #212529;
			}

			h3,
			h4,
			h5 {
				&,
				& b {
					color: var(--primary-color);
				}

				& span {
					color: #212529;
				}
			}

			.p-checkbox .p-checkbox-box .p-checkbox-icon {
				color: #ffffff;
			}
		}

		.p-editor-toolbar {
			background: white;
			border-bottom: none;
		}
	}

	.btnAI {
		padding: 0.4375rem 0.4rem !important;

		&.active,
		&:hover {
			path {
				fill: white;
			}
		}

		path {
			fill: #1b54f8;
		}
	}

	.btnAI-action {
		display: flex;
		flex-direction: row-reverse;

		svg {
			margin-left: 10px;
		}

		&.active,
		&:hover {
			path {
				fill: #1b54f8;
			}
		}

		path {
			fill: #c8d3eb;
		}
	}

	.tippy-box {
		background: #eef5ff;
		color: var(--primary-color);
		font-size: 0.85rem;
		padding: 0.5rem;
		-webkit-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
		box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);

		.tippy-arrow {
			color: #eef5ff;
		}
	}

	.p-dataview.p-component {
		width: 100%;
		height: 100%;
		overflow: hidden;

		&.p-dataview-list {
			& .p-dataview-content {
				overflow: auto;
			}

			&:not(.extend) .p-dataview-content {
				height: 49vh;

				@media screen and (max-width: 991px) {
					height: 40vh;
				}
			}

			&.extend .p-dataview-content {
				min-height: 60vh;
			}
		}

		&.p-dataview-grid {
			& .p-dataview-content {
				overflow: auto;
			}

			&:not(.extend) .p-dataview-content {
				height: 55vh;

				@media screen and (max-width: 991px) {
					height: 44vh;
				}
			}

			&.extend .p-dataview-content {
				min-height: 70vh;
			}
		}

		.background-workplan {
			color: var(--primary-color);
			background-color: #eef5ff;
			font-size: 11px;
		}

		.card-template {
			height: 32vh;
			color: black;

			app-image {
				.loading-spinner {
					height: 85% !important;
					margin: 0 !important;
					padding-top: 15% !important;
				}

				img {
					height: 85% !important;
				}

				.image-empty {
					padding-top: 2vh !important;
					padding-bottom: 2vh !important;
				}
			}

			p {
				height: 15% !important;
				width: 95%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.grid-data {
			&:hover {
				opacity: 0.7;
			}

			.image-grid {
				width: 50%;
				max-width: 8rem;
				object-fit: cover;
			}
		}

		.col-total {
			font-size: 10px !important;
			color: var(--primary-color);
		}

		.col-actions {
			width: 18%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.telescope-button:hover .telescope-icon {
			content: url('./../assets/icons/telescope-white.svg');
		}

		.telescope-button .telescope-icon {
			content: url('./../assets/icons/telescope.svg');
		}

		.telescope-button .telescope-icon.icon-selected {
			content: url('./../assets/icons/telescope-selected.svg');
		}

		.p-button-link:hover .telescope-icon {
			color: #fff !important;
			fill: #fff !important;
			content: url('/assets/icons/telescope-white.svg') !important;
		}

		.p-button-link.icon-selected:hover .telescope-icon {
			color: #fff !important;
			fill: #fff !important;
			content: url('/assets/icons/telescope-white.svg') !important;
		}

		.col-name {
			flex: 0 0 auto;
			padding: 0;
			margin-block: 0.2rem;
			width: 33%;
			font-size: 11px;
		}

		.p-grid.expanded .col-name {
			width: 100%;
		}

		.col-value {
			flex: 0 0 auto;
			padding: 0 0.5rem;
			margin-block: 0.2rem;
			width: 12%;
			font-size: 10px;
		}

		.col-header {
			margin: 0;
		}

		.p-grid.expanded {
			.cell-data.col-name {
				margin-bottom: 0;
			}

			.col-value:not(.col-header) {
				margin-bottom: 0.7rem;
			}

			.col-header p {
				line-height: 1.25rem;
			}

			.col-value {
				width: 16.6%;
			}
		}

		.p-grid {
			.col-header {
				display: none !important;
				text-align: center;
				color: #c8d3eb;
			}

			&.expanded .col-header {
				display: block !important;
			}
		}

		.p-dataview-header.expanded .col-value,
		.p-dataview-header.expanded .col-name {
			// display: none !important;
		}
	}

	.use-case-table-analytics {
		height: 1vh;
		overflow: auto;

		@media screen and (max-width: 991px) {
			height: 45vh;
		}

		&.extend {
			transition: height 500ms;
			height: 72vh;
		}
	}

	.background-heatmap {
		text-align: center;
		height: 2rem;
		border-radius: 5px;
	}

	.background-none {
		background-color: #f9fafc;
	}

	.background-low {
		color: #9f5252;
		background-color: #fbeded;
	}

	.background-medium {
		color: #8d744e;
		background-color: #fcf2d7;
	}

	.background-high {
		color: #579043;
		background-color: #dbf3de;
	}
}

.analytics {
	height: 85%;
	display: block;

	div {
		height: 100%;
		left: -5px;
		margin-right: 3%;

		canvas {
			height: 100% !important;
			width: 100% !important;
			object-fit: cover !important;
		}
	}
}

.button-filter {
	&,
	&.p-highlight {
		background: white !important;
		border: none !important;

		.p-button-icon {
			color: var(--primary-color) !important;
		}
	}
}

.accordion {
	.p-accordion-header {
		display: none !important;
	}

	.p-accordion-content {
		padding: 0 !important;
		border-radius: 0 !important;
		border: none !important;
	}
}

.loading-report {
	z-index: 2000 !important;
}

.no-margin {
	margin: 0;
	padding: 0;
}

.progress-analysis {
	.p-progressbar {
		height: 80%;
		font-size: 0.75rem;
	}

	&.progress-low .p-progressbar .p-progressbar-value {
		background: #994d4d;
	}

	&.progress-medium .p-progressbar .p-progressbar-value {
		background: #99794d;
	}

	&.progress-high .p-progressbar .p-progressbar-value {
		background: #66994d;
	}
}

.progress-analysis-results:before {
	display: none;
}

.icon-selected {
	color: #1b54f8 !important;
	fill: #1b54f8 !important;
}

.p-button-link:hover .pi {
	color: #fff !important;
	fill: #fff !important;
}

.p-button-link.icon-selected:hover .pi {
	color: #fff !important;
	fill: #fff !important;
}
