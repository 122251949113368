.layout-topbar {
    position: fixed;
    height: 2.5rem;
    z-index: 997;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0.6rem 1.2rem 0 1.2rem;
    transition: left $transitionDuration;

    .layout-topbar-logo,
    .layout-topbar-title {
        display: flex;
        align-items: center;
        color: var(--surface-900);
        font-size: 1.5rem;
        font-weight: 500;
        border-radius: 12px;

        img {
            height: 1.5rem;
            margin-right: 0.5rem;
        }
    }

    .layout-topbar-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--text-color-secondary);
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        transition: background-color $transitionDuration;

        &:hover {
            color: var(--text-color);
            background-color: transparent;
        }

        &:focus {
            @include focused();
            box-shadow: none !important;
        }

        i {
            font-size: 1.2rem;
            color: white;
        }

        span {
            font-size: 1rem;
            display: none;
        }
    }

    .no-background .p-button {
        background-color: transparent !important;
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
    }
      
    .no-background .p-button:focus {
        background-color: transparent !important;
        outline: none !important;
        border: none !important;
    }
      
    .no-background .p-button:active {
        background-color: transparent !important;
        outline: none !important;
        border: none !important;
    }
    
    .layout-topbar-menu {
        display: flex;
        gap: 1rem;

        .layout-topbar-button {
            margin-left: 1rem;
            margin-right: 0.8rem;
        }

        .dropdown-organization {
            .p-dropdown {
                background: transparent !important;
                border: none !important;

                .p-inputtext {
                    color: #000 !important;
                }

                .p-dropdown-label {
                    color: #fff !important;
                }

                .p-dropdown-trigger {
                    display: none !important;
                }

                .p-dropdown-panel {
                    .p-dropdown-items {
                        .p-dropdown-item.p-highlight {
                            background: #eef5ff !important;
                            color: var(--primary-color) !important;
                            border-left: 4px solid #1b54f8 !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .layout-topbar {
        justify-content: space-between;
        align-items: center;

        .layout-topbar-logo {
            &.mobile {
                margin-left: 0.6rem;
            }
        }
    }
}
