$text-color: #000 !default;

.ql-editor {
	ul[data-checked] > li,
	ul.check-box-list > li.check-box-list-item {
		pointer-events: none;
	}

	ul[data-checked] > li::before,
	ul.check-box-list > li.check-box-list-item::before {
		color: $text-color;
		border: 1px solid $text-color;
		border-radius: 4px;
		content: ' ';
		font-size: 15px;
		height: 15px;
		line-height: 1;
		position: relative;
		text-align: center;
		vertical-align: middle;
		width: 15px;
		cursor: default;
		pointer-events: all;
	}

	ul[data-checked='true'] > li::before,
	ul.check-box-list > li.check-box-list-item[data-checked='true']::before {
		content: '✔';
	}
}
