/* total width */
*::-webkit-scrollbar {
	background-color: transparent;
	width: 13px;
	height: 13px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
	background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
	background-color: var(--primary-color);
	border-radius: 16px;
	border: 4px solid #fff;
	min-height: 60px;
}

* {
	scrollbar-color: var(--primary-color) transparent;
	scrollbar-width: thin;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
	display: none;
}
