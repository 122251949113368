div.DTED_Lightbox_Wrapper {
	position: fixed;
	top: 0;
	left: 50%;
	margin-left: -390px;
	width: 780px;
	height: 100%;
	z-index: 1101;

	div.DTED_Lightbox_Container {
		display: table;
		height: 100%;
		width: 100%;

		div.DTED_Lightbox_Content_Wrapper {
			display: table-cell;
			vertical-align: middle;
			width: 100%;

			div.DTED_Lightbox_Content {
				position: relative;
				box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.5);
				border-radius: 6px;
				@include box-sizing(border-box);

				div.DTE {
					background: white;
					border-radius: 6px;
					border: 1px solid #666;
					background: #fcfcfc;
					@include box-sizing(border-box);

					div.DTE_Header {
						right: 0;
						width: auto;
						border-top-left-radius: 6px;
						border-top-right-radius: 6px;
					}

					div.DTE_Body_Content {
						box-sizing: border-box;
						background: #fcfcfc;
					}

					div.DTE_Footer {
						right: 0;
						width: auto;
						border-bottom-left-radius: 6px;
						border-bottom-right-radius: 6px;
					}
				}

				div.DTED_Lightbox_Close {
					@include close-icon();
				}
			}
		}
	}
}

div.DTED_Lightbox_Wrapper {
	div.DTE_Footer {
		display: flex;
		justify-content: flex-end;
	}

	div.DTE_Footer_Content {
		display: none;
	}

	div.DTE.inFormError div.DTE_Footer {
		justify-content: space-between;
	}
}

div.DTED_Lightbox_Background {
	@include overlay-background();
}

body.DTED_Lightbox_Mobile {
	div.DTED_Lightbox_Background {
		height: 0;
	}

	div.DTED_Lightbox_Shown {
		display: none;
	}

	div.DTED_Lightbox_Wrapper {
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		width: auto;
		height: auto;
		margin-left: 0;
		-webkit-overflow-scrolling: touch;

		div.DTED_Lightbox_Container {
			display: block;
			height: 100%;

			div.DTED_Lightbox_Content_Wrapper {
				display: block;
				height: 100%;

				div.DTED_Lightbox_Content {
					border-radius: 0;
					box-shadow: none;
					height: 100% !important;

					div.DTE {
						border-radius: 0;
						height: 100%;

						div.DTE_Header {
							border-top-left-radius: 0;
							border-top-right-radius: 0;
						}

						div.DTE_Body_Content {
							padding-bottom: 52px;
						}

						div.DTE_Footer {
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
						}
					}

					div.DTED_Lightbox_Close {
						top: 11px;
						right: 15px;

						background-color: transparent;
						border: none;
						box-shadow: none;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 780px) {
	body div.DTED_Lightbox_Wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 90%;
		margin-left: 5%;
	}
}

@media only screen and (max-width: 580px) {
	body div.DTED_Lightbox_Wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		margin-left: 0;
	}
}

html.dark {
	div.DTED_Lightbox_Wrapper {
		div.DTED_Lightbox_Container {
			div.DTED_Lightbox_Content_Wrapper {
				div.DTED_Lightbox_Content {
					div.DTE {
						border: 1px solid rgba(255, 255, 255, 0.15);
						background: var(--dt-html-background);

						div.DTE_Body_Content {
							background: var(--dt-html-background);
						}
					}
				}
			}
		}
	}
}
