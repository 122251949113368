$input-border: 1px solid #aaa;

$input-border: 1px solid #aaa;

// Generic field styling
div.DTE_Field {
	input,
	textarea {
		box-sizing: border-box;
		background-color: white;
		-webkit-transition: background-color ease-in-out 0.15s;
		transition: background-color ease-in-out 0.15s;
	}

	input:focus,
	textarea:focus {
		background-color: #ffffee;
	}

	input[type='color'],
	input[type='date'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='email'],
	input[type='month'],
	input[type='number'],
	input[type='password'],
	input[type='search'],
	input[type='tel'],
	input[type='text'],
	input[type='time'],
	input[type='url'],
	input[type='week'] {
		padding: 5px 4px;
		width: 100%;

		// Chrome 83 introduces some really ugly borders
		border: $input-border;
		border-radius: 3px;
	}

	input[type='checkbox'],
	input[type='radio'] {
		vertical-align: middle;
	}

	select {
		padding: 5px 4px;
		border-radius: 3px;
		border: $input-border;
		background: white;
		max-width: 100%;
		color: inherit;
	}

	label div.DTE_Label_Info {
		font-size: 0.85em;
		margin-top: 0.25em;

		&:empty {
			margin-top: 0;
		}
	}

	div.DTE_Field_Info,
	div.DTE_Field_Message,
	div.DTE_Field_Error {
		font-size: 11px;
		line-height: 1em;
		margin-top: 5px;

		&:empty {
			margin-top: 0;
		}
	}

	div.DTE_Field_Error {
		display: none;
		color: #b11f1f;
	}

	div.multi-value {
		display: none;
		border: 1px dotted #666;
		border-radius: 3px;
		padding: 5px;
		background-color: #fafafa;
		cursor: pointer;

		span {
			font-size: 0.8em;
			line-height: 1.25em;
			display: block;
			color: #666;
		}

		&.multi-noEdit {
			border: 1px solid #ccc;
			cursor: auto;
			background-color: #fcfcfc;

			&:hover {
				background-color: #fcfcfc;
			}
		}

		&:hover {
			background-color: #f1f1f1;
		}
	}

	&.disabled {
		color: grey;

		div.multi-value {
			cursor: default;
			border: 1px dotted #aaa;
			background-color: transparent;
		}
	}

	div.multi-restore {
		display: none;
		margin-top: 0.5em;
		font-size: 0.8em;
		line-height: 1.25em;
		color: #3879d9;

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

// Specific field type styling
div.DTE_Field_Type_textarea {
	textarea {
		padding: 3px;
		width: 100%;
		height: 80px;
		border: $input-border;
		max-width: 100%;
		max-height: 75vh;
	}
}

div.DTE_Field.DTE_Field_Type_date {
	img {
		vertical-align: middle;
		cursor: pointer;
	}
}

div.DTE_Field_Type_checkbox,
div.DTE_Field_Type_radio {
	div.DTE_Field_Input {
		padding-top: 1px;

		> div > div {
			margin-bottom: 0.25em;

			&:last-child {
				margin-bottom: 0;
			}

			input {
			}

			label {
				margin-left: 0.75em;
				vertical-align: middle;
			}
		}
	}
}

div.DTE_Field_Type_datatable {
	div.dt-layout-row:nth-child(2) > div.dt-layout-cell {
		padding-top: 0;
	}

	div.dt-info,
	div.dataTables_info {
		font-size: 0.8em;
		line-height: 1.3em;
		padding-right: 1em;
		padding-bottom: 0.5em;
		opacity: 0.8;

		span.select-info {
			display: block;
		}
	}

	div.dt-search {
		margin-top: 4px;
	}

	div.dt-search,
	div.dataTables_filter {
		padding-right: 1em;
		padding-bottom: 0.5em;

		input {
			width: 100%;
		}
	}

	div.dt-buttons {
		padding-right: 1em;
		padding-bottom: 0.5em;
		float: none;

		button {
			width: 100%;
			margin-left: 0;
		}

		&:empty {
			padding-bottom: 0;
		}
	}

	div.dataTables_paginate {
		float: none;
		text-align: center;
		font-size: 0.8em;
	}

	table.dataTable {
		&.no-footer {
			border-bottom: 1px solid #aaa !important;
		}

		thead {
			th,
			td {
				font-weight: normal;
				padding-top: 3px;
				padding-bottom: 3px;
				border-bottom: 1px solid #aaa;
			}
		}

		tbody {
			background-color: white;

			th:first-child,
			td:first-child {
				border-left: 1px solid #aaa;
			}

			th:last-child,
			td:last-child {
				border-right: 1px solid #aaa;
			}
		}

		tfoot {
			th,
			td {
				font-weight: normal;
				padding-top: 3px;
				padding-bottom: 3px;
				border-top: 1px solid #aaa;
			}
		}
	}

	div.dataTables_scrollHead {
		table.dataTable,
		table.dataTable thead th,
		table.dataTable thead td {
			border-bottom: none !important;
		}
	}

	div.dataTables_scrollBody {
		border: 1px solid #aaa !important;
		border-radius: 3px;

		table.dataTable {
			border-bottom: none !important;

			tbody {
				th:first-child,
				td:first-child {
					border-left: none;
				}

				th:last-child,
				td:last-child {
					border-right: none;
				}
			}
		}
	}
}

html.dark {
	div.DTE_Field {
		input,
		textarea {
			color: inherit;
			background-color: rgb(33, 37, 41);
		}

		input:focus,
		textarea:focus {
			background-color: rgb(24, 30, 36);
		}

		input[type='color'],
		input[type='date'],
		input[type='datetime'],
		input[type='datetime-local'],
		input[type='email'],
		input[type='month'],
		input[type='number'],
		input[type='password'],
		input[type='search'],
		input[type='tel'],
		input[type='text'],
		input[type='time'],
		input[type='url'],
		input[type='week'] {
			border: 1px solid rgba(255, 255, 255, 0.15);
		}

		select {
			background: rgb(33, 37, 41);
		}

		div.DTE_Field_Error {
			color: #b11f1f;
		}

		div.multi-value {
			border: 1px dotted rgba(255, 255, 255, 0.15);
			background-color: rgba(255, 255, 255, 0.05);

			span {
				color: #b9b9b9;
			}

			&.multi-noEdit {
				border: 1px solid rgba(255, 255, 255, 0.15);
				background-color: rgba(255, 255, 255, 0.05);

				&:hover {
					background-color: rgba(255, 255, 255, 0.05);
				}
			}

			&:hover {
				background-color: rgba(255, 255, 255, 0.1);
			}
		}

		&.disabled {
			color: grey;

			div.multi-value {
				border: 1px dotted rgba(255, 255, 255, 0.15);
			}
		}

		div.multi-restore {
			color: rgb(110, 168, 254);
		}
	}

	// Specific field type styling
	div.DTE_Field_Type_textarea {
		textarea {
			border: 1px solid rgba(255, 255, 255, 0.15);
		}
	}

	div.DTE_Field_Type_datatable {
		table.dataTable {
			&.no-footer {
				border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
			}

			thead {
				th,
				td {
					border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				}
			}

			tbody {
				background-color: rgb(33, 37, 41);

				th:first-child,
				td:first-child {
					border-left: 1px solid rgba(255, 255, 255, 0.15);
				}

				th:last-child,
				td:last-child {
					border-right: 1px solid rgba(255, 255, 255, 0.15);
				}
			}

			tfoot {
				th,
				td {
					border-top: 1px solid rgba(255, 255, 255, 0.15);
				}
			}
		}

		div.dataTables_scrollBody {
			border: 1px solid rgba(255, 255, 255, 0.15) !important;
		}
	}
}
