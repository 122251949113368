@mixin stepper-group($color_border, $color_font) {
	$border: 1px solid $color_border;

	&:nth-child(2) {
		border-left: $border;
		border-radius: 6px 0 0 6px;

		&::after {
			content: 'Readiness';
			position: absolute;
			top: -18px;
			left: 5px;
			padding: 0 5px;
			font-size: 10px;
			letter-spacing: 0.15rem;
			background: transparent;
			color: $color_font;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4) {
		border-top: $border;
		border-bottom: $border;
	}

	&:nth-child(4) {
		border-right: $border;
		border-radius: 0 6px 6px 0;
		-webkit-border-radius: 0 6px 6px 0;
		-moz-border-radius: 0 6px 6px 0;
		-ms-border-radius: 0 6px 6px 0;
		-o-border-radius: 0 6px 6px 0;
	}
}

.cell-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.label-text {
	flex: 1;
}

.button-container {
	position: relative;
	display: inline-block;
	margin-left: auto;
}

.notification-dot {
	position: absolute;
	top: 8px;
	right: 8px;
	height: 8px;
	width: 8px;
	background-color: #cc3333;
	border-radius: 50%;
	z-index: 1;
}

.card-content-benefits {
	height: 100%;
	margin-bottom: 2rem;
	overflow-y: auto;
	padding: 1rem 1.75rem 1rem 1.75rem;

	.grid-main {
		height: calc(88.5vh); //ponerlo en mediaquery?

		.scroll-height-1 {
			height: 42.5vh;
		}

		.header-buttons {
			.p-button:not(.new-solution) {
				border: none !important;
				background: #fff;
				color: #c8d3eb;

				&.active,
				&.activable:hover {
					background: #eef5ff;
					color: var(--primary-color);
				}

				&:hover,
				&:focus {
					color: var(--primary-color);
				}
			}
		}

		.p-tabview-nav-container {
			display: none !important;
		}

		.p-tabview-panels {
			padding: 0 !important;

			.p-tabview-panel {
				height: 100%;
			}
		}

		.scroll-height-2 {
			height: 60vh;
		}

		.analytics {
			margin-top: 1.5rem;
		}
	}

	.tippy-box {
		background-color: #ffffff;
		color: #333;
		-webkit-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
		box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
	}

	.tippy-arrow {
		color: #ffffff;
	}
}

.background-heatmap {
	text-align: center;
	height: 2rem;
	border-radius: 5px;
}

.background-none {
	background-color: #f9fafc;
}

.background-low {
	color: #9f5252;
	background-color: #fbeded;
}

.background-medium {
	color: #8d744e;
	background-color: #fcf2d7;
}

.background-high {
	color: #579043;
	background-color: #dbf3de;
}

.card-content-benefits .tippy-box .tooltip-benefits,
.card-content-benefits .grid-main,
.card-content-benefits.fake {
	.box-base {
		text-align: center;
		padding: 0.5rem;
		border-radius: 5px;
		font-weight: 500;
		font-size: 0.8rem;
		cursor: pointer;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
	}

	.box-low {
		@extend .box-base;
		background: #f2dae2;
		color: #994d4d;
	}

	.box-medium {
		@extend .box-base;
		background: #f7f1d2;
		color: #99794d;
	}

	.box-high {
		@extend .box-base;
		background: #def2da;
		color: #66994d;
	}

	.box-breakthrough {
		@extend .box-base;
		background: #dae6f2;
		color: #1532b8;
	}

	.box-transformative {
		@extend .box-base;
		background: #d7e2f7;
		color: #1b54f8;
	}

	.box-incremental {
		@extend .box-base;
		background: #e1edff;
		color: #569bff;
	}

	.box-benefits {
		@extend .box-base;
		background: var(--gray-100);
		color: var(--primary-color);
	}

	.box-process-data {
		@extend .box-base;
		background: var(--gray-100);
		color: var(--gray-900);
	}

	.box-process-data-active {
		@extend .box-base;
		background: #eef5ff;
		color: var(--primary-color);
	}

	.box-business-data {
		@extend .box-base;
		border: 1px solid #eef5ff;
		border-radius: 6px;
		color: var(--gray-900);
	}
}

.benefits-assessments {
	.p-dialog-content {
		border-radius: 8px !important;

		.card-content-stepper {
			border: none !important;
			padding: 1.5rem 1rem 0.5rem 1rem;
			border-radius: 0;

			.p-steps {
				position: absolute;
				width: 40%;
				right: 5%;
				z-index: 10;
				background: rgba(255, 255, 255, 0.49);

				.p-menuitem-link {
					display: flex;
					flex-direction: column-reverse;
					padding-bottom: 0.5rem;
					cursor: pointer;

					&:focus {
						box-shadow: none !important;
					}
				}

				.p-steps-title {
					font-size: 0.7rem !important;
					margin-bottom: 1rem;
					text-wrap: nowrap !important;
					text-align: center;
					margin-left: 0.2rem;
					margin-right: 0.2rem;
				}

				.p-highlight .p-steps-number {
					background: var(--primary-color) !important;
				}

				.p-highlight .p-steps-title {
					color: var(--primary-color) !important;
				}

				.p-steps-number {
					border: 2px solid var(--primary-color) !important;
					min-width: 1rem !important;
					height: 1rem !important;
					font-size: 0 !important;
					border-radius: 50% !important;
					background: white !important;
				}

				.p-steps-item {
					width: 25%;

					@include stepper-group(#c8d3eb, #c8d3eb);
				}

				.p-steps-item:before {
					height: 2px;
					border: none !important;
					background: var(--primary-color) !important;
					margin-top: 1rem !important;

					@media screen and (max-width: 1650px) {
						margin-top: 0.9rem !important;
					}
				}

				.p-steps-item.p-highlight ~ * {
					&:before {
						background: rgb(69 69 149 / 13%) !important;
					}
				}

				.p-steps-item.p-highlight:before {
					background: linear-gradient(90deg, var(--primary-color) 50%, #1b54f800 110%) !important;
				}
			}

			.close-button {
				top: 1rem;
				right: 1rem;
				position: absolute;
				color: var(--bluegray-300);
				font-size: 0.25rem;
				height: 2rem !important;
				width: 2rem !important;

				&:hover {
					background: var(--bluegray-400) !important;
					border-color: var(--bluegray-400) !important;
					color: white;
				}
			}

			.scroll-height {
				height: calc(100vh - 16.5rem);
				margin-top: 2rem;

				.ngx-slider .ngx-slider-tick-legend {
					position: absolute;
					top: -24px;
				}

				.text-4 {
					color: #383890;
				}

				.text-3 {
					color: #6b9e00;
				}

				.text-2 {
					color: #ff7d00;
				}

				.text-1 {
					color: #5b6770;
				}

				.text-na {
					color: #c1c6c8;
					text-wrap: nowrap !important;
				}
			}

			.p-tabview-panels {
				padding-top: 0;
			}

			.p-tabview-nav-container {
				display: none;
			}

			.stepper {
				background: transparent;
				display: flex;
				width: 100%;
				gap: 5px;
				overflow: auto;
			}

			.box-base {
				text-align: center;
				padding: 0.5rem;
				border-radius: 5px;
				font-weight: 500;
				font-size: 0.8rem;
				cursor: pointer;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				-ms-border-radius: 5px;
				-o-border-radius: 5px;
			}

			.box-process-data {
				@extend .box-base;
				background: var(--gray-100);
				color: var(--gray-900);
			}
		}
	}
}

.benefits-add-process {
	.scroll-height {
		max-height: 50vh;
	}
}
