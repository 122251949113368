/* You can add global styles to this file, and also import other style files */

@import 'normalize.css/normalize.css';

$gutter: 1rem; //for primeflex grid system
@import 'assets/layout/styles/layout/layout';
@import '@fontsource-variable/open-sans';

/* PrimeNG */
@import 'primeng/resources/primeng.css';
@import 'primeflex/primeflex';
@import 'primeicons/primeicons.css';

/* Demos testing */
@import 'assets/demo/styles/flags/flags.css';
@import 'assets/demo/styles/badges';
@import 'assets/demo/styles/code';

/*@import '@angular/material/prebuilt-themes/indigo-pink.css';*/
/*@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';*/

/* RTP */
@import 'assets/layout/styles/theme/bootstrap4-light-blue/theme.css';
@import 'quill/dist/quill.snow.css';
@import 'scss/all';
@import 'tippy.js/dist/tippy.css';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* DAMI CSS */

html {
    height: 100%;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif !important;
}

.layout-main-container {
    padding: 3.5rem 1rem 3.5rem 3.5rem;
}

.card {
    border-radius: 0.5rem;
}

/* Navbar ----------*/

/* Navbar: Menu sidebar ---------- */

.layout-sidebar {
    border-radius: 0 0.5rem 0.5rem 0;
    top: 3.5rem;
    overflow-y: hidden;
    padding: 0 0.5rem;
}

app-sidebar .menu-main.active-route {
    border-radius: 0.25rem 0 0 0.25rem;
}

.layout-menu ul a {
    border-radius: 0.25rem 0 0 0.25rem;
}

.menu-main {
    margin: 1rem 0;
}

/* Container: Menu left sidebar ---------- */
.filter-sidebar input.p-inputtext {
    font-size: 0.875rem;
}

.filter-sidebar.active {
    top: 2px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 0 0.25rem 0.25rem 0;
    transition: none !important;
    padding: 0.286rem;
    font-size: 0.875rem;
    min-height: 2rem;
    line-height: 1.28rem;
    -webkit-border-radius: 0 0.25rem 0.25rem 0;
    -moz-border-radius: 0 0.25rem 0.25rem 0;
    -ms-border-radius: 0 0.25rem 0.25rem 0;
    -o-border-radius: 0 0.25rem 0.25rem 0;
}

/*
  .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    }

    .card-content-ai-explorer .p-treenode-selectable.p-highlight {
        border-left:none !important;
        box-shadow: -5px 0px 0px #1B54F8 ;

    }
    */

/* Section: 1. AI XPLR E2E Taxonomy------------------------------------------------------------------ */

/* heatmap---------- */

/* fonts size */
.card-content-ai-explorer .col-category {
    font-size: 0.875rem;

    &.view-subprocess {
        margin: 1rem 0 0 0;
    }

    &:not(.view-subprocess) {
        margin: 0.5rem 0 0 0 !important;
        padding: 0 !important;
    }
}

.card-content-ai-explorer .grid-header p,
.card-content-ai-explorer .grid-header label {
    font-size: 0.75rem;
}

.card-content-ai-explorer .col-name {
    font-size: 0.875rem;
}

.card-content-ai-explorer .grid-header p,
.card-content-ai-explorer .grid-header label {
    font-size: 0.75rem;
}

.card-content-ai-explorer .col-value {
    padding: 0 0.2rem;
    font-size: 0.875rem;
    font-weight: 600;
}

/* table view----- */

/* text list */
.card-content-ai-explorer .col-category:not(:first-child) {
    border: 1px solid #dee2e6;
    border-width: 1px 0 0 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: none;
    padding: 0;
}

/* traffic light component */
.card-content-ai-explorer .background-heatmap {
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    -ms-border-radius: 0.25rem;
    -o-border-radius: 0.25rem;
    font-size: 0.6rem;
}

/* Diagram view----- */

.card-content-ai-explorer .grid-data .image-grid {
    object-fit: contain;
    aspect-ratio: 1/1;
}

.card-content-ai-explorer .p-dataview.p-component .card-template {
    margin: 0 !important;
    padding: 0.6rem !important;
}

.card-content-ai-explorer .p-dataview.p-component .card-template p {
    text-align: center;
    font-weight: 400;
    font-size: 0.8rem;
}

/*  Section: 2. Solutions------------------------------------------------------------------ */

/* Grid */
.card-content-solutions .p-dataview.p-component .p-grid {
    display: grid;
    gap: 1.5rem;
}

/*  Card */
.card-content-solutions .card-use-case {
    width: 100%;
    /* height: 35vh; */
    min-width: 10vw;
    max-width: 16.5rem;
    object-fit: cover;
}

.card-content-solutions .card-use-case .card-title {
    min-height: 50%;
}

.card-content-solutions .card-use-case .background-card {
    background: rgba(255, 153, 0, 0.05);
    padding: 1rem;
    /*text-wrap: wrap;*/
}

/* Section: 3.Use Cases------------------------------------------------------------------ */

/* Analytics */

/* Backlog */

/* heatmap---------- */
/*  fonts size */

.card-content-use-cases .data-header .grid-header p {
    font-size: 0.75rem;
}

.card-content-use-cases .p-dataview.p-component .col-name,
.card-content-use-cases .data-header .col-name {
    font-size: 0.8rem;
}

.card-content-use-cases .p-dataview.p-component .col-value,
.card-content-use-cases .data-header .col-value {
    font-size: 0.8rem;
}

.card-content-use-cases .p-dataview.p-component .background-heatmap {
    border-radius: 0.25rem;
    font-weight: 600;
}

.card-content-use-cases .p-dataview.p-component .background-workplan {
    font-size: 0.875rem;
}
M
/*y Diagrams---------- */

.card-content-use-cases .p-dataview.p-component .grid-data .image-grid {
    object-fit: contain;
    aspect-ratio: 1/1;
}

.card-content-use-case .p-dataview.p-component .card-template p {
    text-align: center;
    font-weight: 400;
}

.card-content-use-cases .p-dataview.p-component .card-template {
    max-height: 32vh;
    color: black;
}

/* Assessment Modal---------- */

/* Stepper */

.assessments-modal .p-steps .p-steps-title {
    font-size: 0.75rem !important;
    margin-bottom: 1rem;
}

.assessments-modal label {
    font-size: 12px;
}

/* Questions */

.text-stepper {
    font-size: 14px;
    color: #282828;
    width: 100%;
}

.comments {
    border-radius: 5px;
    padding: 4% 2%;
    border: 1px solid #ddd;
}

.card-content-form .grid {
    margin-top: 0 !important;
}

.ngx-slider-inner-tooltip {
    font-size: 12px;
    top: 9px;
    position: relative;
    color: #a2aaad;
}

/* Results */

.new-solution {
    font-size: 0.9rem !important;
    line-height: 1px;
}

.row-table {
    color: #292b2c;

    &:hover {
        background-color: #f8faff;
        cursor: pointer;
        /*border: 1px solid #EEF5FF !important;*/
        color: #1b54f8;
        opacity: 1;

        p {
            color: #1b54f8;
            opacity: 1;
        }
    }
}

.ngx-slider .ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-full-bar {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.py-75 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}
