.menu-topbar {
    &:before,
    &:after {
        display: none;
    }

    .p-overlaypanel-content {
        padding: 0;

        .p-menu {
            border: none;

            .p-menuitem-link:focus {
                box-shadow: inset 0 0 0 0.15rem #eef5ff;
            }
        }

        .change-org-switch {
            height: 1.25rem;

            .p-inputswitch {
                height: 1.25rem;
                border-radius: 4px !important;
        
                .p-inputswitch-slider {
                    border-radius: 4px !important;
        
                    &:before {
                        border-radius: 4px !important;
                    }
                }
        
                &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
                    background: var(--primary-color);
                }

                &.p-focus .p-inputswitch-slider {
                    box-shadow: none;
                }

                .p-inputswitch-slider:before {
                    height: 0.91rem;
                    top: 65%;
                }
            }
        }
    }
}
