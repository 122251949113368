
/*
 * Namespace: DTED - DataTables Editor Display - Envelope
 */

div.DTED_Envelope_Wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	height: 100%;
	z-index: 11;
	display: none;
	overflow: hidden;

	// Create a shadow display at the top of the evelope to make it look like it has
	// come from under the element that it is attached to/ Left and right to give a
	// slight fade and the two ends
	div.DTED_Envelope_Shadow {
		position: absolute;
		top: -10px;
		left: 10px;
		right: 10px;
		height: 10px;
		z-index: 10;
		box-shadow: 0 0 20px black;
	}

	div.DTED_Envelope_Container {
		position: absolute;
		top: 0;
		left: 5%;
		width: 90%;

		border-left: 1px solid #777;
		border-right: 1px solid #777;
		border-bottom: 1px solid #777;
		box-shadow: 3px 3px 10px #555;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		background-color: white;

		div.DTE_Processing_Indicator {
			right: 36px;
		}

		div.DTE_Footer {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		div.DTED_Envelope_Close {
			@include close-icon();
		}
	}
}


div.DTED_Envelope_Background {
	@include overlay-background();
}

html.dark {
	div.DTED_Envelope_Wrapper {
		div.DTED_Envelope_Container {
			border-left: 1px solid rgba(255, 255, 255, 0.15);
			border-right: 1px solid rgba(255, 255, 255, 0.15);
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			box-shadow: 3px 3px 10px #000000;
			background-color: var(--dt-html-background);
		}
	}
}
