.card-content-design-nexus {
	.data-header {
		background: transparent !important;
		border: none !important;
		padding-bottom: 0.5rem;

		.header-buttons {
			.p-button:not(.new-solution) {
				border: none !important;
				background: #eff1fd;
				color: #1b54f8;

				&.active,
				&.activable:hover {
					background: #eef5ff;
					color: var(--primary-color);
				}

				&:hover,
				&:focus {
					color: var(--primary-color);
				}
			}
		}

		.grid-selects {
			color: #7c878e;
			font-size: 11px;
			line-height: 1rem;
			text-align: left;

			.p-dropdown.p-focus {
				box-shadow: none;
				border-color: var(--primary-color);
			}
		}

		.col-name,
		.col-value {
			p {
				line-height: 1rem !important;
			}
		}
	}

	.p-tabview-nav-container {
		display: none !important;
	}

	.p-tabview-panels {
		padding: 0 !important;

		.p-tabview-panel {
			height: 100%;
		}
	}

	.grid-dataview {
		width: 99%;
	}

	.dataview-portfolio {
		.p-dataview-content {
			height: 56vh !important;
			overflow-x: hidden !important;

			.p-grid {
				width: 97%;
			}
		}

		&.paginator {
			.p-dataview-content {
				height: 46vh !important;
			}
		}
	}

	p-dataview {
		width: 100%;

		.p-dataview-header {
			background: transparent !important;

			&,
			& > div {
				padding: 0;
			}
		}
	}

	.label-1 {
		$initWidth: 18;
		$initPx: 880;
		$reductionAmount: 5;
		$pxStep: 30;

		width: $initWidth * 1%;

		@for $i from 0 through 5 {
			$newWidth: $initWidth + ($reductionAmount * $i);
			$newPx: $initPx - ($pxStep * $i);

			@media screen and (max-width: #{$newPx}px) {
				width: $newWidth * 1%;
			}
		}
	}

	.filter-1 {
		$initWidth: 82;
		$initPx: 880;
		$reductionAmount: 5;
		$pxStep: 30;

		width: $initWidth * 1%;
		max-width: $initWidth * 1%;
		min-width: $initWidth * 1%;

		@for $i from 0 through 5 {
			$newWidth: $initWidth - ($reductionAmount * $i);
			$newPx: $initPx - ($pxStep * $i);

			@media screen and (max-width: #{$newPx}px) {
				width: $newWidth * 1%;
			}
		}

		.filter-content {
			width: 100%;
		}
	}

	.label-2 {
		width: 30%;

		@media screen and (max-width: 991px) {
			width: 42%;
		}
	}

	.filter-2 {
		width: 70%;

		.filter-content {
			width: 100%;

			.p-chip-text {
				margin-top: 0 !important;
				margin-bottom: 0 !important;
			}
		}
	}

	.box-select-low {
		.p-chip {
			background: #f2dae2 !important;
			color: #994d4d !important;
		}
	}

	.box-select-medium {
		.p-chip {
			background: #f7f1d2 !important;
			color: #99794d !important;
		}
	}

	.box-select-high {
		.p-chip {
			background: #def2da !important;
			color: #66994d !important;
		}
	}

	.data-ai {
		height: 69vh;

		.button-header {
			border: none !important;
			background: #fff;
			color: #c8d3eb;

			&.active {
				background: #eef5ff;
				color: var(--primary-color);
			}

			&.left {
				border-radius: 4px 0 0 4px;
			}

			&.right {
				border-radius: 0 4px 4px 0;
			}

			&:hover,
			&:focus {
				color: var(--primary-color);

				&:not(.active) {
					background: #fff;
				}
			}
		}

		.content {
			overflow: auto;
			height: 69vh;

			* {
				font-size: 1rem;
				line-height: 1.5rem;
				color: #212529;
			}

			h3,
			h4,
			h5 {
				&,
				& b {
					color: var(--primary-color);
				}

				& span {
					color: #212529;
				}
			}

			.p-checkbox .p-checkbox-box .p-checkbox-icon {
				color: #ffffff;
			}
		}

		.p-editor-toolbar {
			background: white;
			border-bottom: none;
		}
	}

	.btnAI {
		padding: 0.4375rem 0.4rem !important;

		&.active,
		&:hover {
			path {
				fill: white;
			}
		}

		path {
			fill: #1b54f8;
		}
	}

	.btnAI-action {
		display: flex;
		flex-direction: row-reverse;

		svg {
			margin-left: 10px;
		}

		&.active,
		&:hover {
			path {
				fill: #1b54f8;
			}
		}

		path {
			fill: #c8d3eb;
		}
	}

	.tippy-box {
		background: #eef5ff;
		color: var(--primary-color);
		font-size: 0.85rem;
		padding: 0.5rem;
		-webkit-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
		box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);

		.tippy-arrow {
			color: #eef5ff;
		}
	}

	.p-dataview.p-component {
		width: 100%;
		height: 100%;
		padding-left: 1rem;
		overflow: hidden;

		&.p-dataview-list {
			& .p-dataview-content {
				overflow: auto;
			}

			&:not(.extend) .p-dataview-content {
				height: 49vh;

				@media screen and (max-width: 991px) {
					height: 40vh;
				}
			}

			&.extend .p-dataview-content {
				height: 70vh;
			}
		}

		&.p-dataview-grid {
			& .p-dataview-content {
				overflow: auto;
			}

			&:not(.extend) .p-dataview-content {
				height: 55vh;

				@media screen and (max-width: 991px) {
					height: 44vh;
				}
			}

			&.extend .p-dataview-content {
				min-height: 60vh;
			}
		}

		.background-heatmap {
			text-align: center;
			height: 2rem;
			border-radius: 5px;
		}

		.background-none {
			background-color: #f9fafc;
		}

		.background-low {
			color: #9f5252;
			background-color: #fbeded;
		}

		.background-medium {
			color: #8d744e;
			background-color: #fcf2d7;
		}

		.background-high {
			color: #579043;
			background-color: #dbf3de;
		}

		.background-workplan {
			color: var(--primary-color);
			background-color: #eef5ff;
			font-size: 11px;
		}

		.telescope-button:hover .telescope-icon {
			content: url('./../assets/icons/telescope-white.svg');
		}

		.cardlate {
			height: 32vh;
			color: black;

			app-image {
				.loading-spinner {
					height: 85% !important;
					margin: 0 !important;
					padding-top: 15% !important;
				}

				img {
					height: 85% !important;
				}

				.image-empty {
					padding-top: 2vh !important;
					padding-bottom: 2vh !important;
				}
			}

			p {
				height: 15% !important;
				width: 95%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.grid-data {
			&:hover {
				opacity: 0.7;
			}

			.image-grid {
				width: 50%;
				max-width: 8rem;
				object-fit: cover;
			}
		}

		.col-total {
			font-size: 10px !important;
			color: var(--primary-color);
		}

		.col-actions {
			width: 10%;

			.p-button {
				width: 32%;

				&:focus {
					box-shadow: none;
				}
			}
		}

		.col-name {
			flex: 0 0 auto;
			padding: 0;
			margin-block: 0.2rem;
			width: 30%;
			font-size: 11px;
		}

		.p-grid.expanded .col-name {
			width: 100%;
		}

		.col-value {
			flex: 0 0 auto;
			padding: 0 0.5rem;
			margin-block: 0.2rem;
			width: 12%;
			font-size: 10px;
		}

		.col-header {
			margin: 0;
		}

		.p-grid.expanded {
			.cell-data.col-name {
				margin-bottom: 0;
			}

			.col-value:not(.col-header) {
				margin-bottom: 0.7rem;
			}

			.col-header p {
				line-height: 1.25rem;
			}

			.col-value {
				width: 16.6%;
			}
		}

		.p-grid {
			.col-header {
				display: none !important;
				text-align: center;
				color: #c8d3eb;
			}

			&.expanded .col-header {
				display: block !important;
			}
		}
	}

	.use-case-table-analytics {
		height: 55vh;
		overflow: auto;

		@media screen and (max-width: 991px) {
			height: 45vh;
		}

		&.extend {
			transition: height 500ms;
			height: 72vh;
		}
	}

	.col-value,
	.col-actions {
		width: 9.7% !important;
	}

	.background-generated {
		color: var(--primary-color);
		background-color: #eff1fd;
	}

	.background-in-progress {
		color: #00736b;
		background-color: #e7f5f4;
	}

	.background-not-started {
		color: #878d96;
		background-color: #fff;
		border: 1px solid #c4c6cc;
	}

	.background-completed {
		color: #456b00;
		background-color: #eef4e7;
	}
}

.dialog-design-nexus {
	.p-messages .p-message {
		z-index: 115;
		position: absolute;
		top: 8%;
		width: 98%;
		left: 0.75rem;
	}

	.p-tabview-panel .p-button.p-button-link {
		width: 100%;
	}

	.p-tabview-panel .p-button.p-button-link .pi-sitemap:before {
		font-size: 20rem;
	}

	.width-full {
		width: 100%;
		display: block;
		text-align: center;
	}

	.center-all {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -75px 0 0 -75px;
	}

	.btnOpenDiagram {
		margin-left: auto;
		margin-right: auto;
		display: block;

		svg {
			fill: #ffffff;
		}

		opacity: 1;

		:hover {
			opacity: 0.8;
		}
	}

	.p-dialog-content {
		padding: 0 !important;
		color: #292929 !important;
	}

	.p-tabview-nav-container {
		display: none !important;
	}

	.p-tabview-panels {
		padding: 0;
	}

	.sticky-header {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		z-index: 110;
		background: white;
		width: 100%;
	}

	.stepper {
		background: white;
		display: flex;
		width: 100%;
		gap: 5px;
		overflow: auto;
	}

	$shape-inset: 1.25rem;

	.arrow {
		background: #f9fafc;
		color: #7c878e;
		cursor: pointer;
		clip-path: polygon(
				0% 0%,
				calc(100% - #{$shape-inset}) 0%,
				100% 50%,
				calc(100% - #{$shape-inset}) 100%,
				0% 100%,
				#{$shape-inset} 50%
		);
		padding: 1rem calc(1rem + #{$shape-inset});
		position: relative;
		width: calc(100% + #{$shape-inset});
		border-radius: 5px;
		line-height: 16px;
		letter-spacing: 0.038px;
		font-size: 0.8rem;
		font-style: normal;
		font-weight: 600;
		display: flex;
		align-items: center;

		&:first-of-type {
			padding-left: 2rem;
			clip-path: polygon(
					0% 0%,
					calc(100% - #{$shape-inset}) 0%,
					100% 50%,
					calc(100% - #{$shape-inset}) 100%,
					0% 100%
			);
		}

		&:not(:first-of-type) {
			margin-left: -$shape-inset;
		}

		&.active {
			background: linear-gradient(270deg, #d9e8ff 0%, #fff 100%);
			color: #1b54f8;
		}

		&.hoverable:hover {
			background: linear-gradient(270deg, #bbd7ff 0%, #fff 100%);
			color: #1b54f8;
		}
	}

	.buttons-header {
		position: absolute;
		top: 10px;
		right: 1.5rem;
		padding: 0;
		z-index: 10;
		height: 39px;
	}

	.actions-header {
		position: absolute;
		top: 60px;
		right: 1.5rem;
		padding: 0;
		z-index: 10;
		height: 39px;
		border: none !important;
	}

	.buttons-actions {
		position: relative;
		top: 0;
		right: 5rem;
		padding: 0;
		z-index: 10;
	}

	.btnAI {
		&.active,
		&:hover {
			path {
				fill: white;
			}
		}

		path {
			fill: #1b54f8;
		}
	}

	.btnAI-normal {
		path {
			fill: white;
		}
	}

	.table-design-nexus {
		width: 100%;

		th {
			background: #f9fafc;
			text-align: left;
		}

		tr {
			margin-bottom: 1rem;
		}

		td {
			text-align: left;
		}
	}

	.table-input-output-datatable {
		width: 100%;

		.dt-buttons,
		.dt-search {
			display: none !important;
		}

		.dt-layout-row {
			margin: 0 !important;
		}

		table.dataTable > tbody > tr.selected > * {
			box-shadow: inset 0 0 0 9999px #eff1fd;
			color: var(--primary-color);
		}

		table.dataTable {
			border-collapse: collapse;
			width: 100%;
			margin-top: 1rem;

			th,
			td {
				border-bottom: 1px solid #eef5ff;
				padding: 8px;
				text-align: left;
			}

			th {
				background: #f9fafc;
			}
		}
	}
}

.custom-chip {
	font-size: 1rem;
	color: #82858E;

	.p-chip {
		border: 3px solid var(--Cold-Gray-200, #CECFD3);
		border-radius: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px 12px;
		background-color: #fff;
	}

	.p-chip-text {
		font-size: 1rem;
		color: #82858E;
	}

	.pi {
		color: #82858E !important;
	}
}

.DTED.DTED_Lightbox_Wrapper {
	.DTE {
		.DTE_Header {
			color: white;
			background: var(--primary-color);
		}

		.DTE_Footer {
			background: white;
			border: none !important;
		}

		.btn {
			background: var(--primary-color);
			color: white;
			border-radius: 6px;
		}
	}

	.DTED_Lightbox_Close:after {
		color: white;
	}
}

.hubble-design {
	.chip-table {
		.p-chip {
			width: 100%;
			justify-content: center;
		}

		&.Low .p-chip {
			color: var(--theme-green-400) !important;
			background-color: var(--theme-green-50) !important;
		}

		&.Medium .p-chip {
			color: var(--theme-yellow-400) !important;
			background-color: var(--theme-yellow-50) !important;
		}

		&.High .p-chip {
			color: var(--theme-red-400) !important;
			background-color: var(--theme-red-50) !important;
		}
	}

	.progress-bar-table {
		.p-progressbar {
			border: 1px solid var(--theme-blue-100);

			.p-progressbar-value {
				width: 100% !important;
				justify-content: center;
				background: var(--theme-white);

				.p-progressbar-label {
					color: var(--theme-black);
					font-size: 14px;
				}
			}
		}
	}

	.row-level1 {
		background: var(--theme-blue-50);
		color: var(--theme-blue-950);
		font-weight: 500;
	}

	.action-col {
		width: 8rem;
	}

	.button-menu {
		text-align: center;
		height: 2rem;
		border-radius: 5px;
		border: none;
		width: 100%;

		&.status.none,
		&.result.none {
			background-color: #f9fafc;
		}

		&.status.complete {
			color: var(--theme-green-400) !important;
			background-color: var(--theme-green-50) !important;
		}

		&.status.in-progress {
			color: var(--theme-yellow-400) !important;
			background-color: var(--theme-yellow-50) !important;
		}

		&.status.not-started {
			color: var(--theme-red-400) !important;
			background-color: var(--theme-red-50) !important;
		}

		&.result.pass {
			color: var(--theme-green-400) !important;
			background-color: var(--theme-green-50) !important;
		}

		&.result.fail {
			color: var(--theme-red-400) !important;
			background-color: var(--theme-red-50) !important;
		}
	}

	.p-treetable {
		.p-treetable-tbody > tr > td,
		.p-treetable-thead > tr > th {
			padding: 0.5rem 1rem;
		}

		.p-treetable-tbody > tr > td.no-padding {
			padding: 0 3rem 0.5rem;
		}

		.p-treetable-scrollable-header-box {
			padding: inherit !important;
		}

		.p-treetable-tbody > tr > td {
			font-size: 14px;
		}

		.p-treetable-thead > tr > th {
			font-size: 12px;
			border-top: 0 none;
		}
	}

	p-treeTableToggler:not(.level1) {
		.pi-chevron-down {
			color: var(--theme-blue-600);
		}
	}

	table.level3 {
		tr > td {
			padding: 0.5rem 1.5rem;
		}

		tr > th {
			font-weight: 600;
			line-height: 16px;
			letter-spacing: 0.01em;
			font-size: 12px;
			border-radius: 4px;
		}

		tr.th-row > th {
			color: var(--theme-blue-900);
		}

		tr:not(.th-row) > th {
			background: var(--theme-gray-50);
			color: var(--theme-gray-400);
		}

		tr > th.space,
		tr > td.space {
			tr > th.space,
			tr > td.space {
				background: transparent;
				width: 0.5rem;
			}

			tr > th,
			tr > td {
				tr > th,
				tr > td {
					text-align: center;
				}
			}
		}
	}

	// Custom

	.p-treetable-scrollable-body {
		height: calc(var(--h-main-content) - 4rem);
	}
}

.hubble-design-data-sources-table {
	.p-datatable-wrapper {
		min-height: 300px;
	}

	tr > th {
		font-size: 12px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0.01em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000 !important;
		background: #f9fafb !important;
	}

	tr > th,
	tr > td {
		border-bottom: 1px solid var(--Black-100, #e7e7e7) !important;
	}

	tr > td {
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.1599999964237213px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
	}
}

.design-nexus-modal {
	.ngx-slider .ngx-slider-tick-legend {
		position: absolute;
		top: -24px;
	}

	.ngx-slider-ticks {
		.ngx-slider-tick:first-child .ngx-slider-tick-value {
			transform: translate(-10%);
		}

		.ngx-slider-tick:last-child .ngx-slider-tick-value {
			transform: translate(-90%);
		}
	}

	.slider-container {
		width: 100%;
	}
}

.poc-data {
	.p-editor-container .p-editor-content.ql-snow {
		border-top: 1px solid #dee2e6 !important;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}

	.p-editor-toolbar {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		display: none;
	}

	.ql-editor {
		h1, h2, h3, h4, h5, h6, ol, ul {
			padding-bottom: 1rem;
		}
		h1, h2, h3 {
			padding-top: 1rem;
		}

		ol + ul, ul + ol,
		h1 + ol, h1 + ul,
		h2 + ol, h2 + ul,
		h3 + ol, h3 + ul,
		h4 + ol, h4 + ul,
		h5 + ol, h5 + ul,
		h6 + ol, h6 + ul,
		{
			padding-bottom: 0.5rem;
		}

		p {
			line-height: inherit;
		}
	}
}
