.content-editable {
	&:focus-visible {
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
	}

	* {
		font-size: 1rem;
		line-height: 1.5rem;
		color: #212529;
	}

	h3,
	h4,
	h5 {
		& {
			font-weight: bold;
		}

		&,
		& b,
		& strong {
			color: var(--primary-color);
		}

		& span {
			color: #212529;
		}
	}
}

.medium-editor-toolbar {
	background: white !important;
	border: 1px solid var(--surface-border) !important;
	box-shadow: none !important;

	li button {
		background: white !important;
		color: var(--text-color) !important;
		border-right: none !important;
		border-left: none !important;

		&.medium-editor-button-active {
			color: var(--primary-color) !important;

			b {
				font-weight: bold !important;
			}
		}

		b {
			font-weight: normal !important;
		}
	}

	.medium-toolbar-arrow-under::after {
		border-color: var(--surface-border) transparent transparent transparent !important;
		top: 50px;
	}
}
