.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: 1rem;
}

p {
	line-height: 2rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
	background: #eef5ff !important;
	color: var(--primary-color) !important;
	border-radius: 4px;
	border: 1px solid var(--primary-color);
}

.layout-progress {
	width: 80%;

	.p-progressbar {
		background: rgba(0, 0, 0, 0.09);
		border-radius: 15px;

		.p-progressbar-value {
			border: 0 none;
			margin: 0;
			background: var(--primary-color);
		}
	}
}

.layout-menuitem-icon.pi-angle-right {
	margin-left: auto;

	transform: rotate(180deg);
	animation: rotation 0.3s ease-in;
}

.layout-static-inactive .layout-menuitem-icon.pi-angle-right {
	transform: rotate(0deg);
	animation: transform 0.3s ease-out;
}

.cell-heatmap:hover {
	opacity: 0.5;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(180deg);
	}
}

.layout-menu ul a:focus {
	box-shadow: none !important;
}

h3.light {
	color: var(--surface-800);
}

.p-blockui-document {
	background: linear-gradient(180deg, #000 0%, #f6f6f6 100%) !important;
}

.p-datatable .p-datatable-tbody > tr > td {
	padding: 0.3rem 1rem;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
	border: none;
}

.p-datatable .p-datatable-tbody > tr {
	color: black;
}

.p-datatable .p-datatable-thead > tr > th,
.p-button.p-button-link {
	color: rgba(193, 198, 200, 1);
}

.button-edit {
	color: var(--primary-color) !important;

	&:hover {
		color: white !important;
	}
}

.p-paginator {
	.p-paginator-pages .p-paginator-page {
		color: var(--primary-color);

		&.p-highlight {
			background: var(--primary-color);
			border: none;
		}

		&:focus {
			box-shadow: none !important;
		}

		&:hover {
			color: var(--primary-color) !important;
		}
	}

	.p-paginator-first,
	.p-paginator-prev,
	.p-paginator-next,
	.p-paginator-last {
		& {
			color: rgba(193, 198, 200, 1);
		}

		&:not(.p-disabled):not(.p-highlight):hover {
			color: var(--primary-color) !important;
		}

		&:focus {
			box-shadow: none !important;
		}
	}
}

/*opprtunnity-identification*/
.mat-stepper-horizontal {
	background: transparent !important;
}

.mat-horizontal-stepper-header-container {
	margin-bottom: 3rem;
}

.mat-horizontal-content-container {
	padding: 0 !important;
}

.mat-mdc-form-field-infix {
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	min-height: auto;
}

.mat-mdc-form-field-subscript-wrapper {
	display: none;
}

.mat-horizontal-stepper-header-container {
	display: none !important;
}

.layout-static-inactive {
	.menuitem-children {
		display: none;
	}
}

app-sidebar {
	font-size: 14px !important;

	.menu-separator {
		margin-bottom: 1rem;
	}

	.pi-angle-right {
		color: #6390b1 !important;
	}

	.menu-main {
		width: 100% !important;
		display: block;
		color: #3e3e3f !important;
		word-break: break-word;

		&.active-route {
			border-radius: 4px;
			border-right: 4px solid var(--primary-color) !important;
			background: #eef5ff !important;
			font-weight: bold;
			color: #272728 !important;
		}
	}

	.menu-main {
		i {
			color: rgba(77, 83, 88, 1) !important;
		}
	}

	.menu-opportunity-icon {
		i {
			color: rgba(77, 83, 88, 1) !important;
		}
	}

	.menu-automation-icon {
		i {
			color: rgba(0, 191, 181, 1) !important;
		}
	}
}

.p-button-black {
	background: #000 !important;
	border: none !important;

	&:focus {
		box-shadow: none !important;
	}
}

// Card

.card {
	margin-bottom: 0 !important;
}

// Button

.p-button {
	background: var(--primary-color);
	border: 1px solid var(--primary-color);

	&:focus {
		box-shadow: none;
	}

	&:not(.p-disabled):not(.p-highlight):hover {
		background: var(--primary-color);
		border-color: var(--border-primary);
		color: #ffffff;
	}

	&.p-button-text {
		color: var(--primary-color);
	}
}

// Input

.p-inputtext:enabled:focus {
	box-shadow: 0 0 0 0.2rem rgba(69, 69, 149, 0.08);
	border-color: var(--primary-color);
}

// Checkbox

.p-checkbox {
	&,
	&:not(.p-checkbox-disabled) {
		.p-checkbox-box {
			&.p-focus {
				box-shadow: none;
				border-color: var(--primary-color);
			}

			&.p-highlight {
				&,
				&:hover {
					border-color: var(--primary-color);
					background: var(--primary-color);
				}
			}
		}
	}
}

// Autocomplete

.p-autocomplete {
	.p-autocomplete-multiple-container {
		width: 100%;

		&:not(.p-disabled).p-focus {
			box-shadow: 0 0 0 0.2rem rgba(69, 69, 149, 0.08);
			border-color: var(--primary-color);
		}
	}

	.p-autocomplete-token {
		background: #eef5ff !important;
		color: var(--primary-color) !important;
		border-radius: 4px;
		font-size: 14px;
	}
}

// Dropdown

.p-dropdown {
	&:not(.p-disabled).p-focus {
		box-shadow: 0 0 0 0.2rem rgba(69, 69, 149, 0.08);
		border-color: var(--primary-color);
	}
}

// Badge

.p-badge {
	background: var(--primary-color);
}

// Add and edit Icon
i.pi.pi-plus-circle.ng-star-inserted {
	margin-right: 0.5rem;
}

//Add and edit Icon
i.pi.pi-arrow-up-right.ng-star-inserted {
	margin-left: 0.5rem;
}

// Modal

.p-dialog {
	.p-dialog-header {
		border-bottom: none !important;
		padding: 1rem 1rem 1rem 1.5rem;
		background-color: var(--primary-color);

		.p-dialog-title {
			color: var(--primary-color-text);
			font-weight: normal;
		}

		.p-dialog-header-icon {
			color: var(--primary-color-text);

			&:hover {
				color: #6c757d !important;
			}
		}
	}

	.p-dialog-content {
		color: var(--bluegray-500);

		.p-multiselect-token {
			background: #eef5ff;
		}
	}
}

// Multiselect

.p-multiselect {
	&:not(.p-disabled).p-focus {
		box-shadow: 0 0 0 0.2rem rgba(69, 69, 149, 0.08);
		border-color: var(--primary-color);
	}

	&.p-multiselect-chip .p-multiselect-token {
		background: #eef5ff !important;
		color: var(--primary-color) !important;
		border-radius: 4px;
		font-size: 14px;
	}
}

.p-multiselect-item {
	&:focus {
		box-shadow: none !important;
	}

	&.p-highlight {
		background: #eef5ff !important;
		color: var(--primary-color) !important;
		border-radius: 4px !important;
		border: 1px solid var(--primary-color) !important;

		.p-checkbox-box.p-highlight {
			background: var(--primary-color) !important;
		}
	}
}

.p-multiselect-label {
	flex-wrap: wrap;
}

// Chips

.p-chip {
	background: #f9fafc !important;
	color: var(--primary-color) !important;
	border-radius: 4px;
}

// Tree and Treeselect

.p-treeselect {
	&:not(.p-disabled).p-focus {
		box-shadow: 0 0 0 0.2rem rgba(69, 69, 149, 0.08);
		border-color: var(--primary-color);
	}

	&.p-treeselect-chip {
		.p-treeselect-token {
			background: #eef5ff !important;
			color: var(--primary-color) !important;
			border-radius: 4px;
		}
	}
}

.p-tree {
	background: transparent;
	padding: 0.5rem;

	.p-checkbox.p-checkbox-disabled {
		display: none;
	}

	.p-tree-container {
		.p-treenode {
			.p-treenode-content {
				//padding: 0.286rem 0rem !important;

				&:focus {
					//box-shadow: inset 0 0 0 0.15rem var(--primary-color) !important;
				}

				&.p-highlight {
					background: #eef5ff !important;
					color: var(--primary-color) !important;
					margin-left: -3px;

					.p-checkbox-box {
						&.p-highlight {
							background: var(--primary-color) !important;
							color: var(--primary-color) !important;
						}

						.p-checkbox-icon {
							color: #ffffff !important;
						}
					}

					.p-tree-toggler {
						color: var(--primary-color) !important;
					}
				}

				.p-tree-toggler {
					&:focus {
						box-shadow: none;
					}
				}
			}
		}
	}
}

.p-treenode-leaf > .p-treenode-content .p-tree-toggler {
	display: none;
}

.p-treenode-children {
	padding-left: 1.5rem !important;
}

// DataView

p-dataview.p-dataview.p-component.p-dataview-grid.p-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-auto-rows: auto;
	grid-gap: 10px;
}

.p-dataview {
	&.p-dataview-list .p-dataview-header {
		border: 1px solid #dee2e6;
		border-width: 0 0 1px 0;
		padding-right: 7px;
	}

	&.p-dataview-grid .p-dataview-header {
		border: none;
	}
}

// Tooltip

.p-tooltip .p-tooltip-text {
	background: #eef5ff;
	color: var(--primary-color);
}

// Slider

.p-slider {
	.p-slider-range,
	.p-slider-handle {
		background: var(--primary-color);
	}

	.p-slider-handle {
		border-color: var(--primary-color);

		&:focus {
			box-shadow: none;
		}
	}
}

.ngx-slider {
	margin-inline: auto !important;
	margin-block: 2rem !important;
	width: 100% !important;

	.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-full-bar {
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}

	.ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-min {
		margin-left: 3px !important;
		top: -0.35rem !important;
	}

	.ngx-slider-tick:first-child {
		margin-left: 4px !important;
	}

	.ngx-slider-span.ngx-slider-bar {
		margin-left: 4px !important;
		width: 98% !important;
	}

	.ngx-slider-span.ngx-slider-tick-legend .text-na {
		margin-left: 3px !important;
	}

	.ngx-slider-tick:not(:first-child) {
		margin-left: 6px !important;
	}

	.ngx-slider-pointer {
		width: 1rem !important;
		height: 1rem !important;

		&:after {
			width: 0 !important;
			height: 0 !important;
		}
	}
}

// Switch

.p-inputswitch.p-inputswitch-checked {
	&,
	&:not(.p-disabled):hover {
		.p-inputswitch-slider {
			background: var(--primary-color) !important;
		}
	}
}

.p-inputswitch.p-focus .p-inputswitch-slider {
	box-shadow: none;
}

.mat-mdc-slide-toggle {
	margin-top: 0.3rem;

	.mdc-switch__track {
		&,
		&:disabled {
			opacity: 1 !important;

			&:after {
				background: #eef5ff !important;
			}
		}
	}

	.mdc-switch__shadow {
		box-shadow: 0 2px 1px -1px #eef5ff, 0 1px 1px 0 #eef5ff, 0 1px 3px 0 #eef5ff !important;
	}

	.mdc-switch.mdc-switch--selected {
		&:hover,
		&:disabled {
			.mdc-switch__ripple {
				display: block !important;

				&:after,
				&:before {
					background-color: #eef5ff !important;
				}
			}
		}
	}

	.mdc-switch--selected,
	.mdc-switch--checked,
	.mdc-switch:disabled {
		.mdc-switch__handle {
			&:after {
				background: var(--primary-color) !important;
				opacity: 1 !important;
			}
		}
	}

	.mdc-switch__icon {
		margin-left: 0 !important;
	}

	.mdc-switch.mdc-switch--selected:disabled .mdc-switch__icons {
		fill: #fff !important;
		opacity: 1 !important;

		.mdc-switch__icon--on {
			opacity: 1 !important;
		}

		.mdc-switch__icon--off {
			opacity: 0 !important;
		}
	}
}

// Icons for Toolstip technology

.icon-tooltip {
	height: 24px;
	width: 24px;

	.icon {
		fill: rgb(149, 160, 168);
	}

	&.active .icon {
		fill: var(--primary-color);
	}
}

.diagram-modal .tippy-box {
	background-color: #ffffff;
	color: #333;
	-webkit-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
	box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.35);
}

.ngx-slider-span.ngx-slider-bubble.ngx-slider-model-value {
	background-color: red;
}

// Toast

.p-toast-message {
	.p-toast-summary {
		font-size: 14px;
	}

	.p-toast-detail {
		font-size: 12px;
	}

	&.p-toast-message-error {
		.p-toast-message-icon {
			display: none;
		}
	}
}

// Animista

.blink-2 {
	-webkit-animation: blink-2 0.9s infinite both;
	animation: blink-2 0.9s infinite both;
}

//Use Case Assesstments Modal

.assessments-modal .grid.m-0.ng-star-inserted {
	height: 100%;
}

.assessments-modal .col-12.lg\:col-7.xl\:col-7 {
	height: 100%;
}

.assessments-modal .card-content-form[_ngcontent-vyj-c75] {
	overflow-y: hidden;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-3-22 14:11:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-2
 * ----------------------------------------
 */
@-webkit-keyframes blink-2 {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

@keyframes blink-2 {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

.overflow-auto {
	overflow: auto !important;
}

.bg-primary {
	background: var(--primary-color) !important;
}


.p-dialog-mask p img {
	width: 100% !important;
}

.card-content-result img {
	width: 100% !important;
	display: none
}


.attachment-file-upload {
	.p-fileupload .p-fileupload-content {
		padding: 0.3rem 0 0 !important;

		&:has(.card) {
			border: none !important;
		}
	}
}
