.card-content-solution-detail {
    p:last-child {
        margin-bottom: initial;
    }

    .scroller {
        width: 100%;
        height: 82vh;
        overflow: auto;
    }

    .p-button.p-button-text {
        color: #1b54f8;
    }

    .box-simulation-item {
        margin-top: 1rem;
        border: 1px solid #eef5ff;
        border-radius: 6px;
        padding: 0.5rem 1rem;
    }

    .box-simulation-item-content {
        border: 1px solid #eef5ff;
        border-radius: 6px;
        padding: 1rem;
        width: 100%;
        height: 100%;

        p {
            line-height: 1rem;
            font-size: 0.875rem;
            margin: 0;
        }
    }

    .text-subtitle-component {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.0016em;
        text-align: left;
    }

    .text-accordion {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.0016em;
        text-align: left;
        margin: 9px;
    }

    .text-color-normal {
        color: #5b6770;
    }

    .text-size-normal {
        font-size: 0.75rem;
    }

    .text-title {
        font-size: 0.95rem !important;
        margin-bottom: 1rem !important;
    }

    .box-simulation-item-user-case-list {
        border: 1px solid #eef5ff;
        border-radius: 0;
        padding: 0;
        width: 100%;
        height: 100%;

        .box-simulation-item-user-case-list-item {
            border-bottom: 1px solid #eef5ff;
            padding: 1rem;
            width: 100%;
            height: 3rem;

            &.header {
                background: #f9fafc;
                font-weight: bold;
            }

            &.active {
                background: var(--primary-color);
                color: white;
            }
        }
    }

    .box-simulation-item-use-case-text {
        height: 100%;
        padding: 0 1rem;

        p {
            line-height: 1rem;
            font-size: 0.8rem;
            margin: 0;
        }
    }

    .chip {
        background: #eef5ff;
        color: var(--primary-color);
        border-radius: 6px;
        padding: 0.3rem 0.5rem;
        font-size: 0.8rem;
        width: fit-content;
    }

    .box-simulation-item-use-case-ai-enablers {
        border: 1px solid #eef5ff;
        border-radius: 6px;
        padding: 0.5rem 1rem;
    }

    .text-empty {
        width: 30%;
        text-align: center;
        font-size: 1.2rem;
    }

    .text-title {
        font-size: 0.95rem !important;
        margin-bottom: 1rem !important;
    }

    .background-none {
        @extend .background-heatmap;
        background-color: #f9fafc;
    }

    .background-low {
        @extend .background-heatmap;
        color: #9f5252;
        background-color: #fbeded;
        border-radius: 6px;
    }

    .background-medium {
        @extend .background-heatmap;
        color: #8d744e;
        background-color: #fcf2d7;
        border-radius: 6px;
    }

    .background-high {
        @extend .background-heatmap;
        color: #579043;
        background-color: #dbf3de;
        border-radius: 6px;
    }

    .d-flex {
        display: flex;
    }

    .justify-content-end {
        justify-content: flex-end;
    }

    .background-impact {
        @extend .background-heatmap;
        background-color: #e9f3fe;
        border-radius: 6px;
    }

    .background-impact-value {
        @extend .background-heatmap;
        background-color: transparent;
        border-radius: 6px;
        border: 2px solid #e9f3fe;
    }

    .use-case-simulation-item {
        .header-business {
            background-color: #eff1fd;
            text-align: center;
        }
    }

    .header-gray {
        background-color: #e2e8f0;
    }

    .p-accordion {
        // margin-top: 15px;
        // .p-accordion-header-link,

        .p-accordion-content {
            border-radius: 5px !important;
        }

        .p-accordion-header.p-highlight .p-accordion-header-link {
            border-color: #eff1fd;
            background: #eff1fd;
            color: #5152a3;
        }

        .p-accordion-content {
            padding: 0;
        }
    }

    .flow {
        width: 100%;
        height: auto;
    }

    .input-editable {
        border: none;

        &.editable {
            outline: 1px solid var(--primary-color);
        }

        &[contenteditable]:focus {
            outline: 1px solid var(--primary-color);
        }
    }

    .bg-accordion {
        background: none !important;
    }

    .border-solution {
        box-shadow: 12px 0 12px -10px #f0f0f7;
        background: url('../assets/layout/images/logo-back-solution.png') 20px 50% no-repeat;
    }

    .relative {
        position: relative;
    }

    .save-comp {
        position: absolute;
        right: 0;
        /*
		.sub-save {
			position: absolute;
			right: 0;
		}

		.sub-cancel {
			position: absolute;
			left: 0;
		}
		*/
    }

    .icon-tooltip {
        width: 100% !important;
        display: block;
    }
}

.card-content-solution-detail-modal {
    .p-tree.p-component {
        border: none !important;
        padding: 0 !important;
    }

    .p-tree.p-component:not(.multiple-tree) {
        .p-ripple.p-element.p-tree-toggler.p-link {
            display: none !important;
        }
    }
}

.p-multiselect-token-label {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 140px;
    white-space: nowrap;
}
