
// Bubble form editing
// Very similar to the main form, but attached to a particular node and the
// form layout is slightly different with the fields container and buttons
// making up a table of a single row and two columns. This allows the buttons
// to be removed from the display and under this condition the fields will
// take up the full width available.
div.DTE_Bubble {
	position: absolute;
	z-index: 11;
	margin-top: -6px;
	opacity: 0;
	transition: top 0.5s ease-in-out 0s;

	div.DTE_Bubble_Liner {
		position: absolute;
		bottom: 0;
		border: 1px solid black;
		min-width: 300px;
		margin-left: -150px;
		background-color: white;
		box-shadow: 0 12px 30px 0 rgba(0,0,0,.5);
		border-radius: 6px;
		border: 1px solid #666;
		padding: 1em;
		background: #fcfcfc;
		@include box-sizing(border-box);

		div.DTE_Bubble_Table {
			width: 100%;

			> form {
				div.DTE_Form_Content {
					padding: 0;

					div.DTE_Field {
						position: relative;
						margin-bottom: 0.5em;

						&:last-child {
							margin-bottom: 0;
						}

						> label {
							padding-top: 0;
							margin-bottom: 0;
						}

						> div {
							padding: 0;
							margin-top: 0.5em;

							input {
								margin: 0;
							}
						}
					}
				}
			}
			
			div.DTE_Form_Buttons {
				text-align: right;
				margin-top: 1em;

				button {
					margin-bottom: 0;
				}
			}
		}

		div.DTE_Header {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		div.DTE_Header + div.DTE_Form_Info,
		div.DTE_Header + div.DTE_Bubble_Table {
			padding-top: 1em;
		}


		div.DTE_Form_Error {
			float: none;
			display: none;
			padding: 0;
			margin-bottom: 0.5em;
		}

		div.DTE_Bubble_Close {
			@include close-icon();
			top: 6px;
			right: 6px;
			line-height: 18px;
		}
	}

	div.DTE_Bubble_Triangle {
		position: absolute;
		height: 10px;
		width: 10px;
		top: -6px;
		background-color: white;
		border: 1px solid #666;
		border-top: none;
		border-right: none;

		-webkit-transform: rotate(-45deg);
		   -moz-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		     -o-transform: rotate(-45deg);
		        transform: rotate(-45deg);
	}

	&.below {
		div.DTE_Bubble_Liner {
			top: 10px;
			bottom: auto;
		}

		div.DTE_Bubble_Triangle {
			top: 4px;
			-webkit-transform: rotate(135deg);
			   -moz-transform: rotate(135deg);
			    -ms-transform: rotate(135deg);
			     -o-transform: rotate(135deg);
			        transform: rotate(135deg);
		}
	}
}

div.DTE_Bubble_Background {
	@include overlay-background();
}

html.dark,
html[data-bs-theme="dark"] {
	div.DTE_Bubble {
		div.DTE_Bubble_Liner {
			background-color: var(--dt-html-background);
			border: 1px solid rgba(255, 255, 255, 0.15);

			div.DTE_Bubble_Close::after {
				color: rgba(255, 255, 255, 0.5);
			}
		}

		div.DTE_Bubble_Triangle {
			background-color: var(--dt-html-background);
			border-left-color: rgba(255, 255, 255, 0.15);
			border-bottom-color: rgba(255, 255, 255, 0.15);
		}
	}
}