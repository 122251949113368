$hPage: calc(100vh - 4.5rem);
$pPage: 1rem;
$hPageContent: calc(#{$hPage} - 2 * #{$pPage});
$hHeaderContentRel: 0.075;
$hMainContent: calc(#{$hPageContent} * (1 - #{$hHeaderContentRel}));

:root {
	--h-main-content: #{$hMainContent};
}

.page {
	position: relative;
	height: $hPage;
	width: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
	padding: $pPage;

	.hover-element {
		transition: background-color 0.5s ease;
	}
	  
	.hover-element:hover {
		background-color: #EBF2FF;
	}

	background: var(--surface-card);
	border: 1px solid var(--surface-border);
	border-radius: 0.5rem;

	// Utils
	.h-content {
		height: $hMainContent;
	}

	.color-title {
		color: #1e1e1e;
	}

	.color-normal {
		color: #21272a;
	}

	// Content
	.page-content {
		position: relative;
		height: $hPageContent;
		width: 100%;
	}


	.telescope-button:hover .telescope-icon {
		content: url('./../../assets/icons/telescope-white.svg');
	}

	.telescope-button .telescope-icon {
		content: url('./../../assets/icons/telescope.svg');
	}

	.telescope-button .telescope-icon.icon-selected {
		content: url('./../../assets/icons/telescope-selected.svg');
	}

	.p-button-link:hover .telescope-icon {
		color: #fff !important;
		fill: #fff !important;
		content: url('./../../assets/icons/telescope-white.svg') !important;
	}

	.p-button-link.icon-selected:hover .telescope-icon {
		color: #fff !important;
		fill: #fff !important;
		content: url('./../../assets/icons/telescope-white.svg') !important;
	}

	.header-content {
		height: calc(#{$hPageContent} * #{$hHeaderContentRel});
		display: flex;
		flex-direction: row;
		align-content: center;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		flex-wrap: wrap;

		$colorFilterView: #5d5d5d;

		.header-selection-view {
			.p-button {
				color: $colorFilterView;
				background: transparent;
				border: 1px solid $colorFilterView;

				&:first-of-type {
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
				}

				&:last-of-type {
					border-top-right-radius: 6px;
					border-bottom-right-radius: 6px;
				}

				&:not(:last-of-type) {
					border-right: 0;
				}

				&.p-highlight {
					color: white;
					background: var(--primary-color);
					border-color: var(--primary-color);
				}

				&:not(.p-highlight) {
					&:hover,
					&:focus {
						background: #eef5ff;
						color: var(--primary-color);
					}
				}
			}
		}

		.header-button {
			color: $colorFilterView;
			background: #f6f6f6;
			border: 1px solid #f6f6f6;
			border-radius: 6px;

			&.active,
			&:hover {
				background: #eef5ff;
			}

			&.active,
			&:hover,
			&:focus {
				color: var(--primary-color);
			}
		}
	}

	.main-content {
		height: $hMainContent;

		.p-tabview {
			height: $hMainContent;

			.p-tabview-nav-container {
				display: none !important;
			}

			.p-tabview-panels {
				padding: 0 !important;
				height: $hMainContent;

				p-tabpanel,
				.p-tabview-panel {
					height: $hMainContent;
					overflow-y: hidden;
				}
			}
		}

		.size-chart {
			height: calc(#{$hMainContent} - 10rem) !important;
			width: calc(100% - 2rem) !important;
		}

		$frameBox: calc(100rem / 6);

		.box-container {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax($frameBox, 1fr));
			gap: 1rem;
			overflow-y: auto;
			overflow-x: hidden;
			max-height: $hMainContent;
		}

		.box-item {
			background-color: #f0f0f0;
			aspect-ratio: 1 / 1;
			box-sizing: border-box;

			app-image {
				.loading-spinner {
					height: 85% !important;
					margin: 0 !important;
				}

				.image-content {
					width: calc(#{$frameBox} - 4rem);
					object-fit: contain;
					aspect-ratio: 1 / 1;
					padding: 1rem;
				}
			}
		}
	}
}
