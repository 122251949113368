.djs-palette {
	top: 5rem !important;
	left: 1rem !important;
	border: none !important;
	border-radius: 8px;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.02), 0 4px 5px rgba(0, 0, 0, 0.014), 0 2px 4px -1px rgba(0, 0, 0, 0.02) !important;
	width: 96px !important;
}

.djs-minimap {
	top: unset;
	bottom: 0.9rem;
	right: 9rem;
	border-radius: 8px;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.02), 0 4px 5px rgba(0, 0, 0, 0.014), 0 2px 4px -1px rgba(0, 0, 0, 0.02) !important;

	&:not(.open) {
		border: none !important;

		.toggle:before {
			content: 'Minimap';
			font-weight: bold;
			cursor: pointer;
		}
	}
}

.djs-minimap.open .toggle:before {
	content: '\e90b';
	font-family: 'primeicons';
	font-weight: bold;
	cursor: pointer;
}

.bjs-powered-by {
	right: unset !important;
}

.zoom-controls {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	border-radius: 8px;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.02), 0 4px 5px rgba(0, 0, 0, 0.014), 0 2px 4px -1px rgba(0, 0, 0, 0.02) !important;
}

.button-zoom {
	width: 2.5rem;
	height: 2.5rem;
}

.bjs-breadcrumbs {
	position: absolute;
	bottom: 8px;
	left: 0;
	padding-left: 1.2rem;

	li {
		display: inline;

		&:not(:last-child)::after {
			content: '/';
			margin: 0 10px;
			color: var(--bluegray-700);
		}

		.bjs-crumb a {
			color: var(--bluegray-700);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&:last-child .bjs-crumb a {
			color: var(--primary-color);
			font-weight: bold;
		}
	}
}

.custom-icon-taxonomy:before {
	content: '\e9ab' !important;
	font-family: 'primeicons';
	text-align: center;
	cursor: pointer;
}

.custom-icon-taxonomy-go:before {
	content: '\e9a7' !important;
	font-family: 'primeicons';
	text-align: center;
	cursor: pointer;
}

.custom-icon-cognitive:before {
	content: url('/assets/icons/cognitive.svg') !important;
	text-align: center;
}

.custom-icon-data-capture:before {
	content: url('/assets/icons/data-capture.svg') !important;
	text-align: center;
}

.custom-icon-nlp:before {
	content: url('/assets/icons/nlp.svg') !important;
	text-align: center;
}
